import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import "./styles/AddClientUser.scss";
import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch,
    IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
    collection,
    doc,
    getDoc,
    addDoc,
    updateDoc,
    arrayUnion,
    getDocs,
    where,
    query
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from './utils/validate';

const AddClientUser = () => {
    const { currentService, currentUser } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [clientUsers, setClientUsers] = useState([]);
    const [clientConfig, setClientConfig] = useState(null);
    const navigate = useNavigate();

    const [newUserData, setNewUserData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        password: '',
        tipoUsuario: 'cliente'
    });

    useEffect(() => {
        if (currentService?.id) {
            fetchClients();
        }
    }, [currentService?.id]);

    useEffect(() => {
        if (selectedClient) {
            fetchClientConfig();
            fetchClientUsers();
        }
    }, [selectedClient]);

    const fetchClients = async () => {
        try {
            setIsLoading(true);
            const clientsRef = collection(db, 'servicios', currentService.id, 'clientes');
            // Solo obtenemos clientes que tienen configuración del portal
            const q = query(clientsRef, where('hasPortalConfig', '==', true));
            const snapshot = await getDocs(q);
            const clientsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setClients(clientsData);
        } catch (error) {
            console.error('Error al cargar clientes:', error);
            alert('Error al cargar los clientes');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchClientConfig = async () => {
        try {
            const configRef = doc(db, 'servicios', currentService.id, 'clientesConfig', selectedClient);
            const configSnap = await getDoc(configRef);
            
            if (configSnap.exists()) {
                setClientConfig(configSnap.data());
            } else {
                alert('Este cliente no tiene configuración del portal');
                setSelectedClient('');
            }
        } catch (error) {
            console.error('Error al cargar configuración del cliente:', error);
            alert('Error al cargar la configuración del cliente');
        }
    };

    const handleInputChange = (field) => (event) => {
        setNewUserData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    const handleAddClientUser = async () => {
        if (!selectedClient || !clientConfig) {
            alert('Por favor, seleccione un cliente con configuración válida');
            return;
        }

        if (!validateEmail(newUserData.correo)) {
            alert('Por favor, introduce una dirección de correo electrónico válida.');
            return;
        }

        if (newUserData.password.length < 6) {
            alert('La contraseña debe tener al menos 6 caracteres.');
            return;
        }

        try {
            setIsLoading(true);
            const lowercaseEmail = newUserData.correo.toLowerCase();
            
            // Crear usuario en Firebase Auth
            const { user } = await createUserWithEmailAndPassword(auth, lowercaseEmail, newUserData.password);
            
            // Añadir usuario a la colección de usuarios del portal
            const clientPortalUserRef = await addDoc(
                collection(db, 'servicios', currentService.id, 'clientPortalUsers'),
                {
                    userId: user.uid,
                    correo: lowercaseEmail,
                    nombre: newUserData.nombre,
                    apellido: newUserData.apellido,
                    clientId: selectedClient,
                    tipoUsuario: 'cliente',
                    createdAt: new Date(),
                    createdBy: currentUser.uid,
                    active: true
                }
            );

            // Actualizar documento del cliente
            await updateDoc(
                doc(db, 'servicios', currentService.id, 'clientes', selectedClient),
                {
                    portalUsers: arrayUnion(clientPortalUserRef.id)
                }
            );

            alert('Usuario del portal de cliente creado exitosamente');
            navigate(-1);
        } catch (error) {
            console.error('Error al crear usuario del portal:', error);
            alert('Error al crear usuario del portal: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    console.log(selectedClient)
    const fetchClientUsers = async () => {
        try {
            const usersRef = collection(db, 'servicios', currentService.id, 'clientPortalUsers');
            const q = query(usersRef, where('clientId', '==', selectedClient));
            const snapshot = await getDocs(q);
            
            const usersData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt?.toDate()
            }));
            console.log(usersData, " datos de usuarios")
            setClientUsers(usersData);
        } catch (error) {
            console.error('Error al cargar usuarios del cliente:', error);
        }
    };

    const handleToggleUserStatus = async (userId, currentStatus) => {
        try {
            const userRef = doc(db, 'servicios', currentService.id, 'clientPortalUsers', userId);
            await updateDoc(userRef, {
                active: !currentStatus
            });
            
            // Actualizar la lista de usuarios
            fetchClientUsers();
        } catch (error) {
            console.error('Error al actualizar estado del usuario:', error);
            alert('Error al actualizar el estado del usuario');
        }
    };

    return (
        <div className="add-client-user-container">
            <Typography variant="h4" gutterBottom>
                Añadir Usuario al Portal de Cliente
            </Typography>
            
            <div className="form-container">
                <div className="field-container full-width">
                    <FormControl fullWidth>
                        <InputLabel>Cliente</InputLabel>
                        <Select
                            value={selectedClient}
                            onChange={(e) => setSelectedClient(e.target.value)}
                            label="Cliente"
                        >
                            {clients.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                {selectedClient && clientConfig && (
                    <>
                        <div className="field-container">
                            <TextField
                                fullWidth
                                label="Nombre"
                                value={newUserData.nombre}
                                onChange={handleInputChange('nombre')}
                            />
                        </div>
                        
                        <div className="field-container">
                            <TextField
                                fullWidth
                                label="Apellidos"
                                value={newUserData.apellido}
                                onChange={handleInputChange('apellido')}
                            />
                        </div>

                        <div className="field-container">
                            <TextField
                                fullWidth
                                label="Correo"
                                value={newUserData.correo}
                                onChange={handleInputChange('correo')}
                            />
                        </div>

                        <div className="field-container">
                            <TextField
                                fullWidth
                                label="Contraseña"
                                type="password"
                                value={newUserData.password}
                                onChange={handleInputChange('password')}
                            />
                        </div>
                    </>
                )}
            </div>

            {selectedClient && clientConfig && (
                <>
                    <Box className="users-table-section" mt={4}>
                        <Typography variant="h6" gutterBottom>
                            Usuarios Existentes
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Correo</TableCell>
                                        <TableCell>Fecha de Creación</TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell>Acciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clientUsers.map((user) => (
                                        <TableRow key={user.id}>
                                            <TableCell>{`${user.nombre} ${user.apellido}`}</TableCell>
                                            <TableCell>{user.correo}</TableCell>
                                            <TableCell>
                                                {user.createdAt ? new Intl.DateTimeFormat('es-ES', {
                                                    dateStyle: 'medium',
                                                    timeStyle: 'short'
                                                }).format(user.createdAt) : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={user.active}
                                                    onChange={() => handleToggleUserStatus(user.id, user.active)}
                                                    color="primary"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {/* TODO: Implementar edición */}}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {clientUsers.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                No hay usuarios registrados para este cliente
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    <div className="action-buttons">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddClientUser}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <CircularProgress size={24} style={{ marginRight: '8px' }} />
                                    Creando Usuario...
                                </>
                            ) : (
                                'Crear Usuario de Portal'
                            )}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default AddClientUser;