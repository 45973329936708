import React, { useState, useEffect, useContext } from 'react';
import "./table.scss";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from '../../firebase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { AuthContext } from "../../context/AuthContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Función para calcular correctamente la duración de jornadas
const calcularDuracionJornada = (inicio, fin) => {
  if (!inicio || !fin) return null;
  
  // Aseguramos que estamos trabajando con objetos Date
  const inicioDate = inicio instanceof Date ? inicio : new Date(inicio);
  const finDate = fin instanceof Date ? fin : new Date(fin);
  
  // Calculamos la diferencia en milisegundos
  const diferenciaMilisegundos = finDate.getTime() - inicioDate.getTime();
  
  // Convertimos a horas y redondeamos a 2 decimales
  const horas = diferenciaMilisegundos / (1000 * 60 * 60);
  
  // Si la duración es negativa, asumimos que hay un cruce de fecha (fin al día siguiente)
  if (horas < 0) {
    console.warn('Detectada duración negativa, posible registro con fechas incorrectas:', 
      { 
        inicio: inicioDate.toISOString(), 
        fin: finDate.toISOString(),
        horas
      }
    );
    // Tomamos el valor absoluto como fallback, pero debería revisarse este registro
    return Math.abs(horas);
  }
  
  return Math.round(horas * 100) / 100;
};

/**
 * Función auxiliar que detecta si una jornada cruza de un día a otro
 */
const detectaCruceDia = (inicioTimestamp, finTimestamp) => {
  if (!inicioTimestamp || !finTimestamp) return false;
  
  const inicio = new Date(inicioTimestamp);
  const fin = new Date(finTimestamp);
  
  // Si el día del mes es diferente, cruza día
  if (inicio.getDate() !== fin.getDate() || 
      inicio.getMonth() !== fin.getMonth() || 
      inicio.getFullYear() !== fin.getFullYear()) {
    return true;
  }
  
  // Si el día es el mismo pero la hora de fin es menor que la de inicio,
  // probablemente cruza día (aunque registrado con fecha incorrecta)
  if (fin.getHours() < inicio.getHours()) {
    return true;
  }
  
  return false;
};

const JornadasTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);
  const { currentService } = useContext(AuthContext);
  
  useEffect(() => {
    const fetchJornadas = async () => {
      try {
        if (!currentService?.id) {
          console.error("ID de servicio no disponible");
          return;
        }

        console.log("Cargando registros de jornada para servicio:", currentService.id);
        
        // Obtener todos los registros ordenados por timestamp
        const registrosRef = collection(db, `servicios/${currentService.id}/registrosJornada`);
        const q = query(registrosRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        
        console.log(`Registros encontrados: ${querySnapshot.size}`);
        
        // Convertir los documentos a objetos más manejables
        const registros = querySnapshot.docs.map(doc => {
          const data = doc.data();
          const timestamp = data.timestamp?.toDate();
          
          return {
            id: doc.id,
            ...data,
            timestamp: timestamp,
            tipo: data.tipo,
            vigilanteId: data.vigilanteId,
            vigilanteNombre: data.vigilanteNombre,
            vigilanteApellidos: data.vigilanteApellidos,
            vigilanteTipVS: data.vigilanteTipVS,
            vigilanteTiendas: data.vigilanteTiendas,
            vigilanteEmail: data.vigilanteEmail,
            vigilanteTelefono: data.vigilanteTelefono,
            vigilanteBorrado: data.vigilanteBorrado
          };
        });
        
        // Separar por tipo y agrupar por vigilante
        const registrosPorVigilante = {};
        
        registros.forEach(registro => {
          if (!registro.vigilanteId) return;
          
          if (!registrosPorVigilante[registro.vigilanteId]) {
            registrosPorVigilante[registro.vigilanteId] = {
              inicios: [],
              fines: []
            };
          }
          
          if (registro.tipo === 'inicio') {
            registrosPorVigilante[registro.vigilanteId].inicios.push(registro);
          } else if (registro.tipo === 'fin') {
            registrosPorVigilante[registro.vigilanteId].fines.push(registro);
          }
        });
        
        // Array para las jornadas pareadas
        const jornadasPareadas = [];
        
        // Procesar cada vigilante
        Object.entries(registrosPorVigilante).forEach(([vigilanteId, datos]) => {
          // Ordenar por timestamp (más reciente primero)
          datos.inicios.sort((a, b) => b.timestamp - a.timestamp);
          datos.fines.sort((a, b) => b.timestamp - a.timestamp);
          
          // Procesar los fines primero
          datos.fines.forEach(fin => {
            // Buscar el inicio correspondiente (el más reciente anterior a este fin)
            const inicioCorrespondiente = datos.inicios.find(inicio => 
              inicio.timestamp && fin.timestamp && 
              inicio.timestamp < fin.timestamp &&
              // Verificar que este inicio no ha sido usado ya
              !jornadasPareadas.some(j => j.idInicio === inicio.id)
            );
            
            if (inicioCorrespondiente) {
              // Calcular duración
              const duracion = calcularDuracionJornada(inicioCorrespondiente.timestamp, fin.timestamp);
              const cruzaDia = detectaCruceDia(inicioCorrespondiente.timestamp, fin.timestamp);
              
              // Crear jornada pareada
              jornadasPareadas.push({
                id: `${inicioCorrespondiente.id}-${fin.id}`,
                idInicio: inicioCorrespondiente.id,
                idFin: fin.id,
                fechaInicio: inicioCorrespondiente.timestamp,
                fechaFin: fin.timestamp,
                duracion: duracion,
                fechaCruzada: cruzaDia,
                vigilanteId: vigilanteId,
                vigilanteNombre: inicioCorrespondiente.vigilanteNombre || fin.vigilanteNombre,
                vigilanteApellidos: inicioCorrespondiente.vigilanteApellidos || fin.vigilanteApellidos,
                email: inicioCorrespondiente.vigilanteEmail || fin.vigilanteEmail,
                tip: inicioCorrespondiente.vigilanteTipVS || fin.vigilanteTipVS,
                telefono: inicioCorrespondiente.vigilanteTelefono || fin.vigilanteTelefono,
                tiendas: inicioCorrespondiente.vigilanteTiendas || fin.vigilanteTiendas,
                borrado: inicioCorrespondiente.vigilanteBorrado || fin.vigilanteBorrado
              });
            } else {
              // Fin sin inicio correspondiente (poco común, pero posible)
              console.warn(`Fin sin inicio correspondiente para vigilante ${vigilanteId}: ${fin.id}`);
              jornadasPareadas.push({
                id: `fin-${fin.id}`,
                idFin: fin.id,
                fechaFin: fin.timestamp,
                duracion: 'Jornada incompleta',
                vigilanteId: vigilanteId,
                vigilanteNombre: fin.vigilanteNombre,
                vigilanteApellidos: fin.vigilanteApellidos,
                email: fin.vigilanteEmail,
                tip: fin.vigilanteTipVS,
                telefono: fin.vigilanteTelefono,
                tiendas: fin.vigilanteTiendas,
                borrado: fin.vigilanteBorrado
              });
            }
          });
          
          // Procesar inicios sin fin (jornadas activas)
          datos.inicios.forEach(inicio => {
            // Verificar si este inicio ya fue emparejado
            const yaEmparejado = jornadasPareadas.some(j => j.idInicio === inicio.id);
            
            if (!yaEmparejado) {
              jornadasPareadas.push({
                id: `inicio-${inicio.id}`,
                idInicio: inicio.id,
                fechaInicio: inicio.timestamp,
                duracion: 'Jornada no finalizada',
                vigilanteId: vigilanteId,
                vigilanteNombre: inicio.vigilanteNombre,
                vigilanteApellidos: inicio.vigilanteApellidos,
                email: inicio.vigilanteEmail,
                tip: inicio.vigilanteTipVS,
                telefono: inicio.vigilanteTelefono,
                tiendas: inicio.vigilanteTiendas,
                borrado: inicio.vigilanteBorrado
              });
            }
          });
        });
        
        // Convertir tiendas a formato de texto
        const jornadasFormateadas = jornadasPareadas.map(jornada => ({
          ...jornada,
          tiendas: Array.isArray(jornada.tiendas) 
            ? jornada.tiendas.map(t => t.numero).join(', ') 
            : 'N/A'
        }));
        
        console.log(`Jornadas pareadas procesadas: ${jornadasFormateadas.length}`);
        setRows(jornadasFormateadas);
      } catch (error) {
        console.error('Error al cargar las jornadas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJornadas();
  }, [currentService]);

  const columns = [
    {
      field: 'fechaInicio',
      headerName: 'Inicio Jornada',
      width: 180,
      valueFormatter: (params) => 
        params.value ? format(params.value, "dd/MM/yyyy HH:mm", { locale: es }) : 'No registrado',
    },
    {
      field: 'fechaFin',
      headerName: 'Fin Jornada',
      width: 180,
      valueFormatter: (params) => {
        if (!params.value) return 'No registrado';
        
        // Verificar si existe la propiedad fechaCruzada de forma segura
        const isCruzada = params.row && params.row.fechaCruzada === true;
        
        const fechaFormateada = format(params.value, "dd/MM/yyyy HH:mm", { locale: es });
        
        // Solo añadimos indicador si la fecha está marcada como cruzada
        if (isCruzada) {
          return `${fechaFormateada} (día sig.)`;
        }
        return fechaFormateada;
      },
      cellClassName: (params) => {
        // Verificamos de forma segura
        return params.row && params.row.fechaCruzada === true ? 'fecha-cruzada' : '';
      },
    },
    {
      field: 'duracion',
      headerName: 'Duración (horas)',
      width: 150,
      valueFormatter: (params) => 
        typeof params.value === 'number' ? `${params.value} h` : params.value,
    },
    {
      field: 'vigilanteNombre',
      headerName: 'Nombre',
      width: 200,
      valueGetter: (params) => 
        `${params.row.vigilanteNombre || ''} ${params.row.vigilanteApellidos || ''}`.trim(),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220,
    },
    {
      field: 'tip',
      headerName: 'TIP',
      width: 220,
    },
    {
      field: 'telefono',
      headerName: 'Teléfono',
      width: 140,
    }
  ];

  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  const filteredRows = rows.filter(row => showDeleted ? row.borrado : !row.borrado);

  const customLocaleText = {
    noRowsLabel: 'No hay registros',
    footerTotalRows: 'Total de registros:',
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar ASC',
    columnMenuSortDesc: 'Ordenar DESC',
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro de Jornadas</h2>
        <div className="rightActions">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleted}
                onChange={handleShowDeletedChange}
                color="primary"
              />
            }
            label="Mostrar borrados"
          />
        </div>
      </div>
      <DataGridPro
        className="datagrid"
        rows={filteredRows}
        columns={columns}
        loading={loading}
        pageSize={100}
        rowsPerPageOptions={[100]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{ toolbar: GridToolbar }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'fechaInicio', sort: 'desc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              idInicio: false,
              idFin: false,
              vigilanteId: false
            },
          },
        }}
        sx={{
          '& .sin-fin': {
            backgroundColor: '#fff3e0',
          },
          '& .fecha-cruzada': {
            color: '#ff6d00',
            fontWeight: 'bold'
          }
        }}
        localeText={customLocaleText}
      />
    </div>
  );
};

export default JornadasTable;