import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { collection, doc, updateDoc, getDoc, getDocs, where, query } from "firebase/firestore";
import { db } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import JSZip from 'jszip';
import { Button, CircularProgress } from '@mui/material';
import {
    getFolderName, downloadSelectedDocumentsAsPDF,
    downloadSelectedPhotos, downloadSelectedCSV,
    formatDate, DownloadModal
} from './documentUtils';
import { uploadLogToFirestore, uploadErrorLogToFirestore } from '../../utils/logUtils.js';
import { ActionButtons, Table } from './ReportList';
import NewFilterBar from './utils/NewFilterBar';
import { fetchFilteredDocuments, fetchMoreFilteredDocuments, fetchAllFilteredDocuments } from './utils/documentService';
import './table.scss';

// Tamaño de página para la carga de documentos
const ITEMS_PER_PAGE = 50;

/**
 * Componente TableDocuments - Versión optimizada de la tabla de documentos.
 * @returns {JSX.Element} - Elemento de React que representa la tabla de documentos.
 */
const TableDocuments = () => {
    // Estados para los datos y filtros
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);

    // Estados para los nuevos filtros
    const [clienteFilter, setClienteFilter] = useState("");
    const [tipoFilter, setTipoFilter] = useState("");
    const [tipFilter, setTipFilter] = useState("");
    const [fechaInicioFilter, setFechaInicioFilter] = useState("");
    const [fechaFinFilter, setFechaFinFilter] = useState("");

    // Estados para la paginación
    const [currentPage, setCurrentPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    // Estados de carga
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Cargando documentos...");
    const [nombresLoaded, setNombresLoaded] = useState(false);

    // Estados para la descarga
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadFeedback, setDownloadFeedback] = useState(null);
    const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

    // Contexto y parámetros
    const { tipVS } = useParams();
    const { currentUser, currentService, adminData } = useContext(AuthContext);

    // Control de componente montado
    const mounted = useRef(true);

    // Caché de vigilantes
    const vigilantesCache = useRef(new Map());

    // Limpieza al desmontar
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    /**
     * Recupera y agrega el nombre del vigilante a cada documento.
     * @param {Array} rowsToProcess - Filas a procesar
     * @returns {Promise<Array>} - Filas procesadas con nombres
     */
    const populateNombre = useCallback(async (rowsToProcess) => {
        if (!rowsToProcess || rowsToProcess.length === 0 || nombresLoaded) {
            return rowsToProcess;
        }

        try {
            // Obtener solo los documentos que no tienen nombre y tienen vigilante
            const docsWithoutInfo = rowsToProcess.filter(doc =>
                !doc.nombre && doc.vigilante && !vigilantesCache.current.has(doc.vigilante)
            );

            if (docsWithoutInfo.length === 0) {
                // Aplicar información de caché a los documentos
                return rowsToProcess.map(doc => {
                    if (!doc.vigilante) return doc;

                    const vigilanteInfo = vigilantesCache.current.get(doc.vigilante);
                    if (!vigilanteInfo) return doc;

                    return {
                        ...doc,
                        nombre: vigilanteInfo.nombre,
                        vigilanteEmail: vigilanteInfo.email
                    };
                });
            }

            // Obtener emails únicos de vigilantes
            const vigilanteEmails = [...new Set(docsWithoutInfo
                .map(doc => doc.vigilante)
                .filter(Boolean))];

            if (vigilanteEmails.length === 0) {
                return rowsToProcess;
            }

            // Obtener el servicio
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                return rowsToProcess;
            }

            const servicioId = querySnapshot.docs[0].id;

            // Dividir en lotes de 10 (límite de Firestore para consultas 'in')
            const BATCH_SIZE = 10;

            for (let i = 0; i < vigilanteEmails.length; i += BATCH_SIZE) {
                const emailBatch = vigilanteEmails.slice(i, i + BATCH_SIZE);

                // Obtener datos de vigilantes
                const vigilantesQuery = query(
                    collection(db, 'servicios', servicioId, 'vigilantes'),
                    where('email', 'in', emailBatch)
                );

                const vigilantesSnapshot = await getDocs(vigilantesQuery);

                vigilantesSnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    vigilantesCache.current.set(data.email, {
                        nombre: `${data.nombre || ''} ${data.apellidos || ''}`.trim(),
                        email: data.email
                    });
                });
            }

            // Actualizar documentos
            const updatedRows = rowsToProcess.map(doc => {
                if (!doc.vigilante) return doc;

                const vigilanteInfo = vigilantesCache.current.get(doc.vigilante);
                if (!vigilanteInfo) return doc;

                return {
                    ...doc,
                    nombre: vigilanteInfo.nombre,
                    vigilanteEmail: vigilanteInfo.email
                };
            });

            return updatedRows;

        } catch (error) {
            console.error('Error al obtener nombres de vigilantes:', error);
            uploadErrorLogToFirestore('Error en proceso de nombres:', error);
            return rowsToProcess;
        }
    }, [currentUser.email, nombresLoaded]);

    /**
     * Carga inicial de documentos.
     */
    useEffect(() => {
        const loadInitialData = async () => {
            if (!currentService?.id || !adminData) return;

            setIsLoading(true);
            setLoadingMessage("Cargando documentos iniciales...");

            try {
                const result = await fetchFilteredDocuments({
                    serviceId: currentService.id,
                    filters: {},
                    pageSize: ITEMS_PER_PAGE,
                    populateNombreFunc: populateNombre
                });

                setRows(result.documents);
                setLastDoc(result.lastDoc);
                setHasMore(result.hasMore);
                setCurrentPage(1);

            } catch (error) {
                console.error('Error cargando documentos iniciales:', error);
                uploadErrorLogToFirestore('Error al cargar documentos iniciales: ' + error);
            } finally {
                setIsLoading(false);
            }
        };

        if (currentService?.id && adminData) {
            loadInitialData();
        }
    }, [currentService?.id, adminData, populateNombre]);

    /**
     * Actualizar cuando cambia el tipo de servicio.
     */
    useEffect(() => {
        if (mounted.current && currentService?.id && adminData) {
            setLastDoc(null);
            setHasMore(true);
            setRows([]);

            // Resetear filtros
            setClienteFilter("");
            setTipoFilter("");
            setTipFilter("");
            setFechaInicioFilter("");
            setFechaFinFilter("");

            // Cargar datos iniciales
            fetchFilteredDocuments({
                serviceId: currentService.id,
                filters: {},
                pageSize: ITEMS_PER_PAGE,
                populateNombreFunc: populateNombre
            }).then(result => {
                setRows(result.documents);
                setLastDoc(result.lastDoc);
                setHasMore(result.hasMore);
                setCurrentPage(1);
            }).catch(error => {
                console.error('Error al cambiar tipo de servicio:', error);
                uploadErrorLogToFirestore('Error al cambiar tipo de servicio: ' + error);
            });
        }
    }, [tipVS, currentService?.id, adminData, populateNombre]);

    /**
     * Maneja la búsqueda con los filtros aplicados.
     * Carga todos los resultados que coincidan con los filtros.
     */
    const handleSearch = async () => {
        if (!currentService?.id || !adminData || isLoading) return;

        setIsLoading(true);
        setLoadingMessage("Buscando documentos...");

        try {
            // Resetear paginación
            setCurrentPage(1);
            setLastDoc(null);
            setHasMore(false); // No usamos paginación para búsquedas filtradas

            // Crear objeto de filtros
            const filters = {
                cliente: clienteFilter.trim(),
                tipo: tipoFilter.trim(),
                tip: tipFilter.trim(),
                fechaInicio: fechaInicioFilter,
                fechaFin: fechaFinFilter
            };

            // Verificar si hay al menos un filtro activo
            const hasActiveFilters = Object.values(filters).some(value =>
                value && (typeof value !== 'string' || value.trim() !== '')
            );

            if (hasActiveFilters) {
                // Mostrar mensaje de progreso
                setLoadingMessage("Cargando todos los resultados que coinciden con los filtros...");

                // Utilizar la función para cargar todos los documentos filtrados
                const allDocuments = await fetchAllFilteredDocuments({
                    serviceId: currentService.id,
                    filters,
                    populateNombreFunc: populateNombre,
                    progressCallback: (progress) => {
                        setLoadingMessage(`Cargando documentos... (${progress.total} encontrados)`);
                    }
                });

                setRows(allDocuments);
                // No hay más paginación porque ya cargamos todo
                setHasMore(false);
                setLastDoc(null);
            } else {
                // Sin filtros, cargar normalmente la primera página
                const result = await fetchFilteredDocuments({
                    serviceId: currentService.id,
                    filters: {},
                    pageSize: ITEMS_PER_PAGE,
                    populateNombreFunc: populateNombre
                });

                setRows(result.documents);
                setLastDoc(result.lastDoc);
                setHasMore(result.hasMore);
            }

        } catch (error) {
            console.error('Error en la búsqueda:', error);
            uploadErrorLogToFirestore('Error al buscar documentos: ' + error);
            setRows([]);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Limpia todos los filtros y recarga los documentos.
     */
    const handleClearFilters = () => {
        setClienteFilter("");
        setTipoFilter("");
        setTipFilter("");
        setFechaInicioFilter("");
        setFechaFinFilter("");

        // Recargar documentos sin filtros
        if (!isLoading && currentService?.id) {
            setIsLoading(true);
            setLoadingMessage("Cargando documentos...");

            fetchFilteredDocuments({
                serviceId: currentService.id,
                filters: {},
                pageSize: ITEMS_PER_PAGE,
                populateNombreFunc: populateNombre
            }).then(result => {
                setRows(result.documents);
                setLastDoc(result.lastDoc);
                setHasMore(result.hasMore);
                setCurrentPage(1);
            }).catch(error => {
                console.error('Error al limpiar filtros:', error);
                uploadErrorLogToFirestore('Error al limpiar filtros: ' + error);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    /**
     * Carga más documentos (paginación).
     */
    const handleLoadMore = useCallback(async () => {
        if (!isLoading && hasMore && lastDoc && currentService?.id) {
            setIsLoading(true);
            setLoadingMessage("Cargando más documentos...");

            try {
                // Crear objeto de filtros
                const filters = {
                    cliente: clienteFilter.trim(),
                    tipo: tipoFilter.trim(),
                    tip: tipFilter.trim(),
                    fechaInicio: fechaInicioFilter,
                    fechaFin: fechaFinFilter
                };

                // Cargar más documentos
                const result = await fetchMoreFilteredDocuments({
                    serviceId: currentService.id,
                    filters,
                    lastDoc,
                    pageSize: ITEMS_PER_PAGE,
                    populateNombreFunc: populateNombre
                });

                // Añadir a las filas existentes
                setRows(prevRows => [...prevRows, ...result.documents]);
                setLastDoc(result.lastDoc);
                setHasMore(result.hasMore);
                setCurrentPage(prev => prev + 1);

            } catch (error) {
                console.error('Error cargando más documentos:', error);
                uploadErrorLogToFirestore('Error al cargar más documentos: ' + error);
                setHasMore(false);
            } finally {
                setIsLoading(false);
            }
        }
    }, [isLoading, hasMore, lastDoc, clienteFilter, tipoFilter, tipFilter, fechaInicioFilter, fechaFinFilter, currentService?.id, populateNombre]);

    /**
     * Maneja la selección/deselección de todas las filas.
     */
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            const allRowsIds = rows.map((row) => row.id);
            setSelectedRows(allRowsIds);
            setAllRowsSelected(true);
        } else {
            setSelectedRows([]);
            setAllRowsSelected(false);
        }
    };

    /**
     * Maneja la selección/deselección de una fila.
     */
    const handleRowSelection = (event, id) => {
        if (event.target.checked) {
            setSelectedRows(prevSelected => [...prevSelected, id]);

            // Verificar si todas las filas están seleccionadas
            if (selectedRows.length + 1 === rows.length) {
                setAllRowsSelected(true);
            }
        } else {
            setSelectedRows(prevSelected => prevSelected.filter(rowId => rowId !== id));
            setAllRowsSelected(false);

            // Verificar si la fila está marcada como borrada
            if (deletedRows.includes(id)) {
                setDeletedRows(prevDeleted => prevDeleted.filter(rowId => rowId !== id));
            }
        }
    };

    /**
     * Maneja la descarga de documentos seleccionados.
     */
    const handleDownloadSelected = async () => {
        try {
            // Inicializar estado de descarga
            setDownloadProgress(0);
            setDownloadFeedback("Iniciando descarga...");
            setDownloadModalOpen(true);

            if (!currentService?.id) {
                throw new Error("No se encontró el ID del servicio");
            }

            const selectedDocuments = rows.filter((row) => selectedRows.includes(row.id));
            if (selectedDocuments.length === 0) {
                throw new Error("No hay documentos seleccionados");
            }

            const zip = new JSZip();
            const folder = zip.folder('documentos');
            if (!folder) {
                throw new Error("No se pudo crear la carpeta ZIP");
            }

            const totalFiles = selectedDocuments.length;
            let downloadedFiles = 0;

            // Procesar documentos en lotes para evitar sobrecarga de memoria
            const BATCH_SIZE = 5; // Ajustar según sea necesario

            for (let i = 0; i < selectedDocuments.length; i += BATCH_SIZE) {
                const batch = selectedDocuments.slice(i, i + BATCH_SIZE);

                setDownloadFeedback(`Procesando lote ${Math.floor(i / BATCH_SIZE) + 1} de ${Math.ceil(selectedDocuments.length / BATCH_SIZE)}`);

                // Procesar cada lote de forma secuencial
                await Promise.all(batch.map(async (document) => {
                    try {
                        setDownloadFeedback(`Procesando documento ${downloadedFiles + 1} de ${totalFiles}`);

                        let isCustomForm = true;

                        try {
                            const formRef = doc(db, 'servicios', currentService.id, 'forms', document.documentTypeId);
                            const formSnapshot = await getDoc(formRef);

                            if (formSnapshot.exists()) {
                                const formData = formSnapshot.data();

                                if (formData.export) {
                                    const exportTypes = Array.isArray(formData.export) ? formData.export : [formData.export];

                                    for (const exportType of exportTypes) {
                                        try {
                                            switch (exportType) {
                                                case "pdf":
                                                    await downloadSelectedDocumentsAsPDF([document], folder, exportType);
                                                    break;
                                                case "csv":
                                                    await downloadSelectedCSV([document], folder, exportType);
                                                    break;
                                                case "foto":
                                                    await downloadSelectedPhotos([document], folder, exportType);
                                                    break;
                                            }
                                        } catch (exportError) {
                                            console.error(`Error procesando ${exportType} para documento ${document.id}:`, exportError);
                                        }
                                    }
                                } else {
                                    isCustomForm = false;
                                }
                            } else {
                                isCustomForm = false;
                            }
                        } catch (formError) {
                            isCustomForm = false;
                        }

                        if (!isCustomForm) {
                            if (document.documentTypeId === "112") {
                                await downloadSelectedCSV([document], folder);
                            }

                            if (["legal", "ims", "denuncia", "intervencion"].includes(document.documentTypeId)) {
                                await downloadSelectedPhotos([document], folder);
                            }

                            if (["parte de trabajo", "intervencion", "112", "parte_de_trabajo"].includes(document.documentTypeId)) {
                                await downloadSelectedDocumentsAsPDF([document], folder);
                            }
                        }

                        downloadedFiles++;
                        setDownloadProgress((downloadedFiles / totalFiles) * 100);

                    } catch (error) {
                        console.error(`Error procesando documento ${document.id}:`, error);
                    }
                }));

                // Dar tiempo para que el navegador "respire" entre lotes
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            // Generar y descargar ZIP
            setDownloadFeedback("Generando archivo ZIP...");
            const content = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 3 // Usar compresión más ligera para reducir uso de CPU
                }
            });

            // Crear enlace de descarga
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = `documentos_${new Date().toISOString().slice(0, 10)}.zip`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Liberar memoria
            URL.revokeObjectURL(link.href);

            // Actualizar estado de documentos en la base de datos (en lotes para reducir carga)
            setDownloadFeedback("Actualizando estado de documentos...");
            const currentTimeStamp = new Date();

            const updateBatchSize = 20;
            for (let i = 0; i < selectedDocuments.length; i += updateBatchSize) {
                const updateBatch = selectedDocuments.slice(i, i + updateBatchSize);

                await Promise.all(updateBatch.map(async (document) => {
                    try {
                        const docRef = doc(db, 'servicios', currentService.id, 'documents', document.id);
                        await updateDoc(docRef, {
                            downloaded: true,
                            downloadedTime: currentTimeStamp
                        });
                    } catch (error) {
                        console.error(`Error actualizando estado de documento ${document.id}:`, error);
                    }
                }));
            }

            // Actualizar estado UI
            setRows(prevRows => prevRows.map(row => {
                if (selectedRows.includes(row.id)) {
                    return { ...row, downloaded: true, downloadedTime: currentTimeStamp };
                }
                return row;
            }));

            setSelectedRows([]);
            setAllRowsSelected(false);
            setDownloadFeedback("Descarga completada con éxito");
            uploadLogToFirestore('Documentos descargados exitosamente');

        } catch (error) {
            console.error("Error en la descarga:", error);
            setDownloadFeedback(`Error: ${error.message}`);
            uploadErrorLogToFirestore(`Error general en la descarga: ${error.message}`);
        } finally {
            setTimeout(() => {
                if (downloadFeedback && downloadFeedback.includes("éxito")) {
                    setDownloadModalOpen(false);
                }
            }, 3000);
        }
    };

    /**
     * Maneja la eliminación de documentos seleccionados.
     */
    const handleDeleteSelected = async () => {
        try {
            if (!currentService?.id) {
                throw new Error("No se encontró el ID del servicio");
            }

            if (selectedRows.length === 0) {
                return;
            }

            const currentTimeStamp = new Date();

            // Actualizar UI primero para respuesta inmediata
            setRows(prevRows => prevRows.map(row => {
                if (selectedRows.includes(row.id)) {
                    return { ...row, borrado: true, borradoTime: currentTimeStamp };
                }
                return row;
            }));

            // Actualizar lista de filas borradas
            setDeletedRows(prevDeleted => [...prevDeleted, ...selectedRows]);

            // Desmarcar todas las filas seleccionadas
            setSelectedRows([]);
            setAllRowsSelected(false);

            // Actualizar documentos en la base de datos (en lotes)
            const BATCH_SIZE = 20;
            for (let i = 0; i < selectedRows.length; i += BATCH_SIZE) {
                const batch = selectedRows.slice(i, i + BATCH_SIZE);

                await Promise.all(batch.map(async (id) => {
                    try {
                        const docRef = doc(db, 'servicios', currentService.id, 'documents', id);
                        await updateDoc(docRef, {
                            borrado: true,
                            borradoTime: currentTimeStamp
                        });
                    } catch (error) {
                        console.error(`Error al marcar documento ${id} como borrado:`, error);
                        uploadErrorLogToFirestore(`Error al borrar documento ${id}: ${error.message}`);
                    }
                }));
            }

            uploadLogToFirestore(`${selectedRows.length} documentos marcados como borrados`);

        } catch (error) {
            console.error("Error al eliminar documentos:", error);
            uploadErrorLogToFirestore(`Error general al eliminar: ${error.message}`);
        }
    };

    /**
     * Filtra las filas para mostrar solo las no borradas.
     */
    const filteredRows = rows.filter(row => !deletedRows.includes(row.id));

    return (
        <div className="tableContainer">
            <h2 className="datatableTitle">Reportes</h2>

            <NewFilterBar
                clienteFilter={clienteFilter}
                setClienteFilter={setClienteFilter}
                tipoFilter={tipoFilter}
                setTipoFilter={setTipoFilter}
                tipFilter={tipFilter}
                setTipFilter={setTipFilter}
                fechaInicioFilter={fechaInicioFilter}
                setFechaInicioFilter={setFechaInicioFilter}
                fechaFinFilter={fechaFinFilter}
                setFechaFinFilter={setFechaFinFilter}
                handleSearch={handleSearch}
                handleClearFilters={handleClearFilters}
                isLoading={isLoading}
            />

            <DownloadModal
                isOpen={isDownloadModalOpen}
                onClose={() => setDownloadModalOpen(false)}
                progress={downloadProgress}
                feedback={downloadFeedback}
            />

            <div className="tableControlsRow">
                <ActionButtons
                    handleDownloadSelected={handleDownloadSelected}
                    selectedRows={selectedRows}
                    handleDeleteSelected={handleDeleteSelected}
                />
            </div>

            {isLoading ? (
                <div className="loading-container">
                    <CircularProgress size={40} />
                    <p className="loading-message">{loadingMessage}</p>
                </div>
            ) : filteredRows.length === 0 ? (
                <div className="no-results">
                    <p>No se encontraron documentos con los filtros aplicados.</p>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClearFilters}
                    >
                        Limpiar filtros
                    </Button>
                </div>
            ) : (
                <>
                    <Table
                        filteredRows={filteredRows}
                        allRowsSelected={allRowsSelected}
                        selectedRows={selectedRows}
                        handleSelectAllRows={handleSelectAllRows}
                        handleRowSelection={handleRowSelection}
                        handleDeleteSelected={handleDeleteSelected}
                    />

                    {hasMore && (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                            {/* <Button
                                onClick={handleLoadMore}
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <>
                                        <CircularProgress size={20} style={{ marginRight: '8px' }} color="inherit" />
                                        Cargando...
                                    </>
                                ) : (
                                    'Cargar más'
                                )}
                            </Button> */}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TableDocuments;