import { collection, query, where, orderBy, limit, startAfter, getDocs, Timestamp } from "firebase/firestore";
import { db } from '../../../firebase';

/**
 * Servicio para gestionar consultas de documentos en Firestore.
 * Contiene las funciones relacionadas con la obtención y filtrado de documentos.
 */

// Constantes
const MAX_DOCS_PER_QUERY = 1000; // Máximo número de documentos a cargar en una consulta

/**
 * Crea una consulta para filtrar documentos según los filtros aplicados.
 * Carga la mayor cantidad posible de documentos.
 * 
 * @param {Object} params - Parámetros de la consulta
 * @param {string} params.serviceId - ID del servicio
 * @param {Object} params.filters - Filtros a aplicar (cliente, tipo, tip, fechaInicio, fechaFin)
 * @param {Object} [params.lastDoc] - Último documento para paginación
 * @returns {Object} Objeto de consulta de Firestore
 */
export const createFilterQuery = ({ serviceId, filters, lastDoc = null }) => {
  if (!serviceId) return null;
  
  const documentsRef = collection(db, "servicios", serviceId, "documents");
  
  // Estrategia: aplicar un filtro principal en el servidor y el resto en el cliente
  // Para maximizar resultados relevantes
  
  // Por defecto, ordenamos por fecha descendente
  let queryConstraints = [orderBy("fecha", "desc")];
  
  // Determinar qué filtro aplicar a nivel servidor
  // Prioridad: fecha > tipo > cliente > tip
  
  if (filters.fechaInicio || filters.fechaFin) {
    // Aplicar filtros de fecha en el servidor
    if (filters.fechaInicio) {
      const startDate = new Date(filters.fechaInicio);
      startDate.setHours(0, 0, 0, 0);
      queryConstraints.push(where("fecha", ">=", Timestamp.fromDate(startDate)));
    }
    
    if (filters.fechaFin) {
      const endDate = new Date(filters.fechaFin);
      endDate.setHours(23, 59, 59, 999);
      queryConstraints.push(where("fecha", "<=", Timestamp.fromDate(endDate)));
    }
  } 
  else if (filters.tipo && filters.tipo.trim()) {
    // Si no hay filtro de fecha, aplicamos filtro de tipo
    queryConstraints = [
      where("documentTypeId", ">=", filters.tipo),
      where("documentTypeId", "<=", filters.tipo + '\uf8ff'),
      orderBy("documentTypeId"),
      orderBy("fecha", "desc")
    ];
  }
  else if (filters.cliente && filters.cliente.trim()) {
    // Si no hay filtro de fecha ni tipo, aplicamos filtro de cliente
    queryConstraints = [
      where("tienda", ">=", filters.cliente),
      where("tienda", "<=", filters.cliente + '\uf8ff'),
      orderBy("tienda"),
      orderBy("fecha", "desc")
    ];
  }
  else if (filters.tip && filters.tip.trim()) {
    // Si no hay otros filtros, intentamos aplicar filtro de TIP
    queryConstraints = [
      where("tipVS", ">=", filters.tip),
      where("tipVS", "<=", filters.tip + '\uf8ff'),
      orderBy("tipVS"),
      orderBy("fecha", "desc")
    ];
  }
  
  // Añadir paginación si es necesario
  if (lastDoc) {
    queryConstraints.push(startAfter(lastDoc));
  }
  
  // Siempre intentamos cargar el máximo de documentos
  queryConstraints.push(limit(MAX_DOCS_PER_QUERY));
  
  console.log("Query constraints:", queryConstraints);
  
  return query(documentsRef, ...queryConstraints);
};

/**
 * Aplica filtros del lado del cliente a los documentos obtenidos.
 * 
 * @param {Array} documents - Documentos a filtrar
 * @param {Object} filters - Filtros a aplicar
 * @returns {Array} Documentos filtrados
 */
export const applyClientSideFilters = (documents, filters) => {
  let filteredDocs = [...documents];
  
  console.log("Aplicando filtros en cliente:", filters);
  console.log("Documentos antes de filtrar:", filteredDocs.length);
  
  // SIEMPRE filtramos documentos borrados del lado del cliente
  filteredDocs = filteredDocs.filter(doc => !doc.borrado);
  console.log("Después de filtrar borrados:", filteredDocs.length);
  
  // Filtrado por fecha del lado del cliente (complementario)
  if (filters.fechaInicio || filters.fechaFin) {
    filteredDocs = filteredDocs.filter(doc => {
      // Verificar que el documento tiene timestamp
      if (!doc.timestamp) return false;
      
      const docDate = new Date(doc.timestamp);
      
      // Aplicar filtro de fecha de inicio
      if (filters.fechaInicio) {
        const startDate = new Date(filters.fechaInicio);
        startDate.setHours(0, 0, 0, 0);
        if (docDate < startDate) return false;
      }
      
      // Aplicar filtro de fecha de fin
      if (filters.fechaFin) {
        const endDate = new Date(filters.fechaFin);
        endDate.setHours(23, 59, 59, 999);
        if (docDate > endDate) return false;
      }
      
      return true;
    });
    console.log("Después de filtrar fechas:", filteredDocs.length);
  }
  
  // Filtro por tipo
  if (filters.tipo && filters.tipo.trim()) {
    // Aplicar filtro de tipo solo si no se aplicó ya en el servidor
    // (es decir, si hay otros filtros con mayor prioridad como fecha)
    if (filters.fechaInicio || filters.fechaFin) {
      filteredDocs = filteredDocs.filter(doc => 
        doc.documentTypeId && doc.documentTypeId.includes(filters.tipo)
      );
      console.log("Después de filtrar tipo:", filteredDocs.length);
    }
  }
  
  // Filtro por cliente/tienda
  if (filters.cliente && filters.cliente.trim()) {
    // Aplicar filtro de cliente solo si no se aplicó ya en el servidor
    if (filters.fechaInicio || filters.fechaFin || filters.tipo) {
      filteredDocs = filteredDocs.filter(doc => 
        (doc.tienda && doc.tienda.includes(filters.cliente)) ||
        (doc.cliente && doc.cliente.includes(filters.cliente))
      );
      console.log("Después de filtrar cliente:", filteredDocs.length);
    }
  }
  
  // Filtro por tipVS
  if (filters.tip && filters.tip.trim()) {
    // Aplicar filtro de TIP solo si no se aplicó ya en el servidor
    if (filters.fechaInicio || filters.fechaFin || filters.tipo || filters.cliente) {
      filteredDocs = filteredDocs.filter(doc => {
        // Comprobar diferentes posibles ubicaciones del TIP
        return (
          // Campo principal tipVS
          (doc.tipVS && doc.tipVS.includes(filters.tip)) ||
          // Otras posibles ubicaciones como respaldo
          (doc.fields && doc.fields.TIP && doc.fields.TIP.includes(filters.tip))
        );
      });
      console.log("Después de filtrar tip:", filteredDocs.length);
    }
  }
  
  return filteredDocs;
};

/**
 * Procesa los documentos obtenidos de Firestore para adaptarlos al formato requerido.
 * 
 * @param {Array} docs - Documentos de Firestore
 * @returns {Array} Documentos procesados
 */
export const processDocuments = (docs) => {
  return docs.map((doc) => {
    const documentData = doc.data();
    const timestamp = documentData.fecha;
    let date = new Date();
    
    if (timestamp) {
      if (timestamp.toDate) {
        // Si es un Timestamp de Firestore
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        // Si es un objeto con seconds y nanoseconds
        date = new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000);
      }
    }
    
    return { 
      id: doc.id, 
      ...documentData,
      timestamp: date.getTime(),
      fecha: date.toLocaleString('es-ES')
    };
  });
};

/**
 * Obtiene todos los documentos que coinciden con los filtros aplicados.
 * Realiza múltiples consultas si es necesario para obtener todos los resultados.
 * 
 * @param {Object} params - Parámetros de búsqueda
 * @param {string} params.serviceId - ID del servicio
 * @param {Object} params.filters - Filtros a aplicar
 * @param {Function} [params.populateNombreFunc] - Función para enriquecer datos con nombres
 * @param {Function} [params.progressCallback] - Función para reportar progreso
 * @returns {Promise<Array>} Todos los documentos filtrados
 */
export const fetchAllFilteredDocuments = async ({
  serviceId,
  filters,
  populateNombreFunc = null,
  progressCallback = null
}) => {
  try {
    // Limpiar filtros vacíos
    const cleanFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value && (typeof value !== 'string' || value.trim() !== '')) {
        cleanFilters[key] = value;
      }
    });
    
    console.log("Iniciando búsqueda de todos los documentos con filtros:", cleanFilters);
    
    let allDocuments = [];
    let lastDoc = null;
    let hasMore = true;
    let batchCount = 0;
    
    // Función para reportar progreso
    const reportProgress = (batch, total, isComplete = false) => {
      if (progressCallback) {
        progressCallback({
          batch,
          total,
          isComplete,
          message: isComplete 
            ? `Carga completa: ${total} documentos encontrados`
            : `Cargando lote ${batch}: ${total} documentos hasta ahora`
        });
      }
    };
    
    // Cargar documentos en lotes hasta que no haya más
    while (hasMore) {
      batchCount++;
      
      // Crear consulta para el lote actual
      const q = createFilterQuery({ 
        serviceId, 
        filters: cleanFilters,
        lastDoc
      });
      
      if (!q) break;
      
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        hasMore = false;
        break;
      }
      
      // Procesar documentos de este lote
      const batchDocuments = processDocuments(querySnapshot.docs);
      
      // Aplicar filtros del lado del cliente
      const filteredBatch = applyClientSideFilters(batchDocuments, cleanFilters);
      
      // Añadir al resultado total
      allDocuments = [...allDocuments, ...filteredBatch];
      
      // Reportar progreso
      reportProgress(batchCount, allDocuments.length);
      
      // Verificar si hay más documentos para cargar
      if (querySnapshot.docs.length < MAX_DOCS_PER_QUERY) {
        hasMore = false;
      } else {
        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      }
      
      // Límite de seguridad: máximo 10 lotes (10,000 documentos)
      if (batchCount >= 10) {
        console.log("Límite de seguridad alcanzado: 10 lotes");
        hasMore = false;
      }
    }
    
    console.log(`Búsqueda completa: ${allDocuments.length} documentos encontrados en ${batchCount} lotes`);
    
    // Añadir nombres si se proporciona la función
    if (populateNombreFunc && typeof populateNombreFunc === 'function') {
      allDocuments = await populateNombreFunc(allDocuments);
    }
    
    // Reportar que la carga está completa
    reportProgress(batchCount, allDocuments.length, true);
    
    return allDocuments;
    
  } catch (error) {
    console.error('Error al obtener todos los documentos filtrados:', error);
    throw error;
  }
};

/**
 * Obtiene documentos filtrados con paginación estándar.
 * Útil para carga inicial o paginación manual.
 * 
 * @param {Object} params - Parámetros de búsqueda
 * @param {string} params.serviceId - ID del servicio
 * @param {Object} params.filters - Filtros a aplicar
 * @param {Object} [params.lastDoc] - Último documento para paginación
 * @param {number} [params.pageSize=50] - Tamaño de página
 * @param {Function} [params.populateNombreFunc] - Función para enriquecer datos con nombres
 * @returns {Promise<Object>} Resultados de la búsqueda
 */
export const fetchFilteredDocuments = async ({ 
  serviceId, 
  filters, 
  lastDoc = null, 
  pageSize = 50,
  populateNombreFunc = null
}) => {
  try {
    // Log para depuración
    console.log("Filtrando con:", { 
      serviceId, 
      filters, 
      hasLastDoc: !!lastDoc, 
      pageSize 
    });
    
    // Crear un clon de los filtros y limpiar valores vacíos
    const cleanFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value && (typeof value !== 'string' || value.trim() !== '')) {
        cleanFilters[key] = value;
      }
    });
    
    console.log("Filtros limpios:", cleanFilters);
    
    // Si hay filtros activos, intentamos cargar todos los documentos
    if (Object.keys(cleanFilters).length > 0) {
      // Crear una consulta que intente obtener la mayor cantidad posible
      const q = createFilterQuery({ serviceId, filters: cleanFilters, lastDoc });
      
      if (!q) {
        console.log("No se pudo crear la consulta");
        return { 
          documents: [], 
          lastDoc: null, 
          hasMore: false 
        };
      }
      
      const querySnapshot = await getDocs(q);
      console.log(`Consulta retornó ${querySnapshot.docs.length} documentos`);
      
      if (querySnapshot.empty) {
        return { 
          documents: [], 
          lastDoc: null, 
          hasMore: false 
        };
      }
      
      // Procesar documentos
      let documents = processDocuments(querySnapshot.docs);
      
      // Aplicar filtros del lado del cliente
      const beforeClientFilter = documents.length;
      documents = applyClientSideFilters(documents, cleanFilters);
      console.log(`Filtrado cliente: ${beforeClientFilter} -> ${documents.length} documentos`);
      
      // Añadir nombres si se proporciona la función
      if (populateNombreFunc && typeof populateNombreFunc === 'function') {
        documents = await populateNombreFunc(documents);
      }
      
      // Determinar si hay más documentos
      const hasMore = querySnapshot.docs.length >= MAX_DOCS_PER_QUERY;
      
      return {
        documents,
        lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore,
        originalCount: querySnapshot.docs.length
      };
    } 
    else {
      // Sin filtros, usamos paginación normal
      const q = query(
        collection(db, "servicios", serviceId, "documents"),
        orderBy("fecha", "desc"),
        limit(pageSize)
      );
      
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return { 
          documents: [], 
          lastDoc: null, 
          hasMore: false 
        };
      }
      
      let documents = processDocuments(querySnapshot.docs);
      
      // Sólo filtramos documentos borrados
      documents = documents.filter(doc => !doc.borrado);
      
      // Añadir nombres si se proporciona la función
      if (populateNombreFunc && typeof populateNombreFunc === 'function') {
        documents = await populateNombreFunc(documents);
      }
      
      return {
        documents,
        lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
        hasMore: documents.length >= pageSize,
        originalCount: querySnapshot.docs.length
      };
    }
    
  } catch (error) {
    console.error('Error al obtener documentos filtrados:', error);
    throw error;
  }
};

/**
 * Obtiene más documentos para paginación con filtros aplicados.
 * 
 * @param {Object} params - Parámetros de búsqueda
 * @param {string} params.serviceId - ID del servicio
 * @param {Object} params.filters - Filtros a aplicar
 * @param {Object} params.lastDoc - Último documento para paginación
 * @param {number} [params.pageSize=50] - Tamaño de página
 * @param {Function} [params.populateNombreFunc] - Función para enriquecer datos con nombres
 * @returns {Promise<Object>} Resultados de la búsqueda adicional
 */
export const fetchMoreFilteredDocuments = async ({
  serviceId,
  filters,
  lastDoc,
  pageSize = 50,
  populateNombreFunc = null
}) => {
  if (!lastDoc) {
    throw new Error('Se requiere el último documento para paginar');
  }
  
  return fetchFilteredDocuments({
    serviceId,
    filters,
    lastDoc,
    pageSize,
    populateNombreFunc
  });
};

/**
 * Obtiene documentos sin filtrar, solo con paginación.
 * 
 * @param {Object} params - Parámetros de búsqueda
 * @param {string} params.serviceId - ID del servicio
 * @param {Object} [params.lastDoc] - Último documento para paginación
 * @param {number} [params.pageSize=50] - Tamaño de página
 * @param {Function} [params.populateNombreFunc] - Función para enriquecer datos con nombres
 * @returns {Promise<Object>} Resultados de la búsqueda
 */
export const fetchDocuments = async ({
  serviceId,
  lastDoc = null,
  pageSize = 50,
  populateNombreFunc = null
}) => {
  return fetchFilteredDocuments({
    serviceId,
    filters: {},
    lastDoc,
    pageSize,
    populateNombreFunc
  });
};