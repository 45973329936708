import React, { useContext } from 'react';
import ClientPortalConfig from '../../components/admin/ClientPortalConfig';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import "./user.scss";
import { useSidebarState } from '../../components/sidebar/SidebarStateContext';

const ProfileContainer = () => {
  const { collapsed } = useSidebarState();

  return (
    <div className={`user ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className={`userContainer ${collapsed ? 'collapsed' : ''}`}>
        <Navbar />
        <ClientPortalConfig />
      </div>
    </div>
  );
};

export default ProfileContainer;
