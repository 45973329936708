import React from 'react';
import { CircularProgress } from '@mui/material';
import './NewFilterBar.css';

/**
 * Componente de barra de filtros con diseño mejorado.
 */
const NewFilterBar = ({ 
  clienteFilter, 
  setClienteFilter, 
  tipoFilter, 
  setTipoFilter, 
  tipFilter, 
  setTipFilter,
  fechaInicioFilter,
  setFechaInicioFilter,
  fechaFinFilter,
  setFechaFinFilter,
  handleSearch,
  handleClearFilters,
  isLoading
}) => {
  return (
    <div className="new-filter-bar">
      <div className="filter-controls">
        {/* Primera fila de filtros */}
        <div className="filter-row">
          <div className="filter-item">
            <label htmlFor="cliente-filter">Servicio</label>
            <input
              id="cliente-filter"
              type="text"
              placeholder="Buscar por servicio"
              value={clienteFilter}
              onChange={(e) => setClienteFilter(e.target.value)}
              className="filter-input"
            />
          </div>
          
          <div className="filter-item">
            <label htmlFor="tipo-filter">Tipo de documento</label>
            <input
              id="tipo-filter"
              type="text"
              placeholder="Buscar por tipo"
              value={tipoFilter}
              onChange={(e) => setTipoFilter(e.target.value)}
              className="filter-input"
            />
          </div>
          
          <div className="filter-item">
            <label htmlFor="tip-filter">TIP</label>
            <input
              id="tip-filter"
              type="text"
              placeholder="Buscar por TIP"
              value={tipFilter}
              onChange={(e) => setTipFilter(e.target.value)}
              className="filter-input"
            />
          </div>
        </div>
        
        {/* Contenedor de rango de fechas */}
        <div className="date-range-container">
          <div className="date-range-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="16" y1="2" x2="16" y2="6"></line>
              <line x1="8" y1="2" x2="8" y2="6"></line>
              <line x1="3" y1="10" x2="21" y2="10"></line>
            </svg>
            Rango de fechas
          </div>
          
          <div className="date-range-filters">
            <div className="filter-item">
              <label htmlFor="fecha-inicio-filter">Desde</label>
              <input
                id="fecha-inicio-filter"
                type="date"
                value={fechaInicioFilter}
                onChange={(e) => setFechaInicioFilter(e.target.value)}
                className="filter-input date-input"
              />
            </div>
            
            <div className="filter-item">
              <label htmlFor="fecha-fin-filter">Hasta</label>
              <input
                id="fecha-fin-filter"
                type="date"
                value={fechaFinFilter}
                onChange={(e) => setFechaFinFilter(e.target.value)}
                className="filter-input date-input"
              />
            </div>
          </div>
        </div>
        
        {/* Botones de acción */}
        <div className="filter-actions">
          <button 
            className="clear-button"
            onClick={handleClearFilters}
            disabled={isLoading}
          >
            Limpiar filtros
          </button>
          
          <button 
            className="search-button"
            onClick={handleSearch}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress size={20} style={{ color: 'white' }} />
                <span>Buscando...</span>
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                Buscar
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewFilterBar;