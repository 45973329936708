// src/services/schedulerService.js

class SchedulerService {
  constructor() {
    // El endpoint debe incluir la versión de la API
    this.API_ENDPOINT = 'https://optimization.googleapis.com/optimization/v1/workforceScheduling:solve';
    this.API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  }

  async optimizeSchedule(params) {
    try {
      const requestBody = this.transformToGoogleApiFormat(params);
      console.log('Request body:', JSON.stringify(requestBody, null, 2));
      
      const url = `${this.API_ENDPOINT}?key=${this.API_KEY}`;
      console.log('Making request to:', url);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Full response:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries(response.headers.entries()),
          body: errorText
        });
        throw new Error(`API error (${response.status}): ${errorText}`);
      }

      const result = await response.json();
      return this.transformFromGoogleApiFormat(result);
    } catch (error) {
      console.error('Full error details:', error);
      throw error;
    }
  }

  transformToGoogleApiFormat(params) {
    const { vigilantes, restricciones, periodoOptimizacion, turnos } = params;

    // Crear el modelo de optimización según la documentación
    return {
      model: {
        shiftConstraints: {
          maxShiftsPerWeek: restricciones.maxDiasContinuos,
          minRestBetweenShifts: restricciones.minHorasDescanso * 60, // convertir a minutos
          maxConsecutiveWorkingDays: restricciones.maxDiasContinuos,
          maxWorkingTimePerWeek: restricciones.maxHorasSemanales * 60 // convertir a minutos
        },
        shifts: turnos.map((turno, index) => ({
          shiftId: `shift_${index}`,
          startTime: {
            hours: parseInt(turno.inicio.split(':')[0]),
            minutes: parseInt(turno.inicio.split(':')[1])
          },
          duration: {
            seconds: this.calculateDurationMinutes(turno.inicio, turno.fin) * 60 // convertir a segundos
          },
          requiredEmployees: Math.ceil(vigilantes.length / turnos.length)
        })),
        employees: vigilantes.map(v => ({
          employeeId: v.id.toString(),
          availability: {
            startDate: {
              year: new Date(periodoOptimizacion.inicio).getFullYear(),
              month: new Date(periodoOptimizacion.inicio).getMonth() + 1,
              day: new Date(periodoOptimizacion.inicio).getDate()
            },
            endDate: {
              year: new Date(periodoOptimizacion.fin).getFullYear(),
              month: new Date(periodoOptimizacion.fin).getMonth() + 1,
              day: new Date(periodoOptimizacion.fin).getDate()
            }
          }
        }))
      },
      solverConfig: {
        maxComputationDuration: { seconds: 300 }
      }
    };
  }

  calculateDurationMinutes(inicio, fin) {
    const [startHour, startMin] = inicio.split(':').map(Number);
    const [endHour, endMin] = fin.split(':').map(Number);
    
    let duration = (endHour * 60 + endMin) - (startHour * 60 + startMin);
    if (duration < 0) duration += 24 * 60;
    
    return duration;
  }

  transformFromGoogleApiFormat(result) {
    console.log('Raw API response:', result);
    
    // Verificar si hay una solución válida
    if (!result.solution || !result.solution.assignments) {
      throw new Error('No se generó una solución válida');
    }

    // Transformar las asignaciones al formato esperado
    const assignments = result.solution.assignments;
    
    // Agrupar por empleado
    const employeeSchedules = {};
    
    assignments.forEach(assignment => {
      const employeeId = assignment.employeeId;
      if (!employeeSchedules[employeeId]) {
        employeeSchedules[employeeId] = {
          vigilanteId: employeeId,
          turnos: []
        };
      }
      
      employeeSchedules[employeeId].turnos.push({
        fecha: this.formatDate(assignment.startTime),
        inicioTurno: this.formatTime(assignment.startTime),
        finTurno: this.formatTime(assignment.endTime)
      });
    });

    return Object.values(employeeSchedules);
  }

  formatDate(dateTime) {
    return `${dateTime.year}-${String(dateTime.month).padStart(2, '0')}-${String(dateTime.day).padStart(2, '0')}`;
  }

  formatTime(dateTime) {
    return `${String(dateTime.hours).padStart(2, '0')}:${String(dateTime.minutes).padStart(2, '0')}`;
  }
}

export default new SchedulerService();