import "./details.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from '../../firebase';
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { formatFecha } from "./DetailsUtils";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { uploadErrorLogToFirestore } from "../../utils/logUtils"

/**
 * Componente de la página de detalles de un documento.
 *
 * @returns {JSX.Element} Elemento JSX que representa la página de detalles de un documento.
 */
const Details = () => {
  const { id } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  /**
 * Establece el estado `isMounted` en `true` al montar el componente y lo establece en `false` al desmontarlo.
 */
  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    /**
  * Recupera los datos del documento y los almacena en el estado `documentData`.
  */
    const fetchDocumentData = async () => {
      try {
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const documentoRef = doc(servicioDoc.ref, "documents", id);
          const documentSnapshot = await getDoc(documentoRef);

          if (documentSnapshot.exists() && isMounted) {
            setDocumentData(documentSnapshot.data());
            break;
          }
        }
      } catch (err) {
        console.log(err);
        uploadErrorLogToFirestore(err)
      }
    };

    fetchDocumentData();
  }, [id, isMounted]);

  // Función auxiliar para procesar imgKey
  const processImgKey = (imgKey) => {
    const parts = imgKey.split('_');
    if (parts.length === 2 && !isNaN(parts[1])) {
      // Incrementa el número en uno y reconstruye la clave
      return `${parts[0]} ${parseInt(parts[1], 10) + 1}`;
    }
    // Si no hay un número o el formato no es el esperado, deja la clave como está
    return imgKey;
  };

  /**
 * Checks if a value is a base64 signature
 * @param {string} value - The value to check
 * @returns {boolean} - True if the value is a signature in base64 format
 */
  const isSignature = (value) => {
    return typeof value === 'string' &&
      (value.startsWith('data:image/png;base64,') ||
        value.startsWith('data:image/jpeg;base64,') ||
        // Also check for raw base64 content that might be missing the prefix
        (value.length > 100 && /^[A-Za-z0-9+/=]+$/.test(value)));
  };

  /**
   * Ensures a base64 signature has the proper prefix
   * @param {string} value - The signature data
   * @returns {string} - Normalized signature with proper prefix
   */
  const normalizeSignature = (value) => {
    if (!value) return value;

    // If already in correct format, return as is
    if (value.startsWith('data:image/')) {
      return value;
    }

    // If it's just the base64 string without prefix
    if (!value.includes(';base64,') && /^[A-Za-z0-9+/=]+$/.test(value)) {
      return `data:image/png;base64,${value}`;
    }

    return value;
  };


  /**
 * Renderiza los campos del documento.
 *
 * @returns {JSX.Element|null} Elemento JSX que representa los campos del documento o `null` si no hay datos.
 */
  const renderFields = () => {
    if (!documentData) {
      return null;
    }

    return Object.entries(documentData.fields).map(([key, value]) => {
      const formattedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

      if (value === null) {
        return (
          <div className="detailItem" key={key}>
            <span className="itemKey">{key}:</span>
            <span className="itemValue">Campo vacío</span>
          </div>
        );
      }
      // New condition: Check if this is a signature field
      if (isSignature(value) || key.toLowerCase().includes('firma')) {
        const normalizedSignature = normalizeSignature(value);
        return (
          <div className="detailItem" key={key}>
            <span className="itemKey">{formattedKey}:</span>
            <div className="imageContainer">
              <img src={normalizedSignature} alt={`${formattedKey}`} className="itemValue formImg" />
            </div>
          </div>
        );
      }
      if (typeof value === "string" && value.startsWith("https://")) {
        return (
          <div className="detailItem" key={key}>
            <span className="itemKey">{formattedKey}:</span>
            <div className="imageContainer">
              <a href={value} className="imageLink" target="_blank" rel="noopener noreferrer">
                <img src={value} alt={key} className="itemValue formImg" />
              </a>
            </div>
          </div>
        );

      } else if (typeof value === "object") {
        return Object.entries(value).map(([imgKey, imgValue], index) => {
          // Utiliza la función auxiliar para obtener el nuevo imgKey procesado
          const newKey = processImgKey(imgKey);
          return (
            <div className="detailItem" key={`${key}-${imgKey}`}>
              <span className="itemKey">{`${formattedKey}: ${newKey}.`}</span>
              <div className="imageContainer">
                <a href={imgValue} className="imageLink" target="_blank" rel="noopener noreferrer">
                  <img src={imgValue} alt={`${key} (${newKey})`} className="itemValue formImg" />
                </a>
              </div>
            </div>
          );
        });
      } else {
        if (key === "observaciones") {
          // Agregar puntos y aparte para el campo "observaciones"
          const observacionesLines = value.split('\n').map((line, index) => (
            <div key={`${key}-${index}`} className="itemValue">{line}</div>
          ));
          return (
            <div className="detailItem" key={key}>
              <span className="itemKey">{formattedKey}:</span>
              <div className="observaciones">{observacionesLines}</div>
            </div>
          );
        } else {
          return (
            <div className="detailItem" key={key}>
              <span className="itemKey">{formattedKey}:</span>
              <span className="itemValue">{value}</span>
            </div>
          );
        }
      }
    });
  };

  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "detailContainer collapsed" : "detailContainer";

  return (
    <div className="detail">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="details">
          <h1 className="title">{documentData?.documentTypeId}</h1>
          <div className="top">
            <div className="left">
              <div className="detailItem">
                <div className="top-info">
                  <span className="vigilante">Vigilante: &nbsp;</span>
                  <span className="vigilante">{documentData?.vigilante.replace('@gmail.com', '')}</span>
                  <span className="fecha">{formatFecha(documentData?.fecha)}</span>
                </div>
              </div>
              <div className="separator"></div>
              <div className="itemsContainer">{renderFields()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
