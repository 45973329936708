import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    Checkbox,
    Typography,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    collection,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    query,
    getDocs
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import "./styles/ClientPortalConfig.scss";

const ClientPortalConfig = () => {
    const { currentService } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedClientConfig, setSelectedClientConfig] = useState(null);
    const [configuredClients, setConfiguredClients] = useState([]);
    const [previewLogo, setPreviewLogo] = useState(null);

    const navigate = useNavigate();

    const defaultConfig = {
        modules: {
            documentos: {
                enabled: false,
                requiresValidation: true
            },
            cuadrantes: {
                enabled: false,
                requiresValidation: true
            },
            vigilantes: {
                enabled: false,
                requiresValidation: true
            },
            controlHorario: {
                enabled: false,
                requiresValidation: true
            }
        },
        theme: {
            primaryColor: '#1976d2',
            logo: null
        }
    };

    useEffect(() => {
        if (currentService?.id) {
            fetchClients();
            fetchConfiguredClients();
        }
    }, [currentService?.id]);

    useEffect(() => {
        if (selectedClient) {
            fetchClientConfig();
        }
    }, [selectedClient]);

    const fetchClients = async () => {
        try {
            setIsLoading(true);
            const clientsRef = collection(db, 'servicios', currentService.id, 'clientes');
            const snapshot = await getDocs(clientsRef);
            const clientsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setClients(clientsData);
        } catch (error) {
            console.error('Error al cargar clientes:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchClientConfig = async () => {
        try {
            setIsLoading(true);
            const configRef = doc(db, 'servicios', currentService.id, 'clientesConfig', selectedClient);
            const configSnap = await getDoc(configRef);
            
            if (configSnap.exists()) {
                const config = configSnap.data();
                setSelectedClientConfig(config);
                if (config.theme.logo) {
                    setPreviewLogo(config.theme.logo.url);
                }
            } else {
                setSelectedClientConfig(defaultConfig);
                setPreviewLogo(null);
            }
        } catch (error) {
            console.error('Error al cargar configuración del cliente:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchConfiguredClients = async () => {
        try {
            const configsRef = collection(db, 'servicios', currentService.id, 'clientesConfig');
            const configsSnapshot = await getDocs(configsRef);
            
            const configsData = [];
            for (const configDoc of configsSnapshot.docs) {
                const clientDoc = await getDoc(doc(db, 'servicios', currentService.id, 'clientes', configDoc.id));
                if (clientDoc.exists()) {
                    configsData.push({
                        clientId: configDoc.id,
                        clientName: clientDoc.data().nombre,
                        ...configDoc.data()
                    });
                }
            }
            
            setConfiguredClients(configsData);
        } catch (error) {
            console.error('Error al cargar configuraciones:', error);
        }
    };

    const handleLogoUpload = async (file) => {
        try {
            setIsUploading(true);
            
            // Crear URL para previsualización
            const previewUrl = URL.createObjectURL(file);
            setPreviewLogo(previewUrl);

            // Crear referencia en Storage
            const logoRef = ref(storage, `servicios/${currentService.id}/client-logos/${selectedClient}/${file.name}`);
            
            // Subir archivo
            await uploadBytes(logoRef, file);
            
            // Obtener URL
            const downloadURL = await getDownloadURL(logoRef);
            
            // Actualizar estado
            handleThemeChange('logo', {
                url: downloadURL,
                name: file.name
            });

        } catch (error) {
            console.error('Error al subir el logo:', error);
            alert('Error al subir el logo');
            setPreviewLogo(null);
        } finally {
            setIsUploading(false);
        }
    };

    const handleRemoveLogo = () => {
        setPreviewLogo(null);
        handleThemeChange('logo', null);
    };

    const handleModuleConfigChange = (module, field) => (event) => {
        setSelectedClientConfig(prev => ({
            ...prev,
            modules: {
                ...prev.modules,
                [module]: {
                    ...prev.modules[module],
                    [field]: event.target.checked
                }
            }
        }));
    };

    const handleThemeChange = (field, value) => {
        setSelectedClientConfig(prev => ({
            ...prev,
            theme: {
                ...prev.theme,
                [field]: value
            }
        }));
    };

    const handleEditConfig = (clientId) => {
        setSelectedClient(clientId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getEnabledModulesCount = (modules) => {
        return Object.values(modules).filter(module => module.enabled).length;
    };

    const handleSaveConfig = async () => {
        if (!selectedClient || !selectedClientConfig) return;

        try {
            setIsLoading(true);
            const configRef = doc(db, 'servicios', currentService.id, 'clientesConfig', selectedClient);
            await setDoc(configRef, selectedClientConfig);

            const clientRef = doc(db, 'servicios', currentService.id, 'clientes', selectedClient);
            await updateDoc(clientRef, {
                hasPortalConfig: true
            });

            fetchConfiguredClients();
            alert('Configuración guardada exitosamente');
        } catch (error) {
            console.error('Error al guardar la configuración:', error);
            alert('Error al guardar la configuración');
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="client-portal-config-container">
            <Typography variant="h4" gutterBottom>
                Configuración del Portal de Cliente
            </Typography>

            <FormControl fullWidth className="select-client">
                <InputLabel>Seleccionar Cliente</InputLabel>
                <Select
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    label="Seleccionar Cliente"
                >
                    {clients.map((client) => (
                        <MenuItem key={client.id} value={client.id}>
                            {client.nombre}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedClient && selectedClientConfig && (
                <>
                    <Box className="module-config-section">
                        <Typography variant="h6" gutterBottom>
                            Configuración de Módulos
                        </Typography>
                        {Object.entries(selectedClientConfig.modules).map(([module, config]) => (
                            <div key={module} className="module-config-item">
                                <Typography variant="subtitle1">
                                    {module.charAt(0).toUpperCase() + module.slice(1)}
                                </Typography>
                                <div className="module-controls">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={config.enabled}
                                                onChange={handleModuleConfigChange(module, 'enabled')}
                                            />
                                        }
                                        label="Activado"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={config.requiresValidation}
                                                onChange={handleModuleConfigChange(module, 'requiresValidation')}
                                                disabled={!config.enabled}
                                            />
                                        }
                                        label="Requiere Validación"
                                    />
                                </div>
                            </div>
                        ))}
                    </Box>

                    <Box className="theme-section">
                        <Typography variant="h6" gutterBottom>
                            Personalización
                        </Typography>
                        <TextField
                            label="Color Primario"
                            type="color"
                            value={selectedClientConfig.theme.primaryColor}
                            onChange={(e) => handleThemeChange('primaryColor', e.target.value)}
                            fullWidth
                            className="color-picker"
                        />
                        
                        <Box className="logo-section" mt={2}>
                            <Button
                                component="label"
                                startIcon={<CloudUploadIcon />}
                                variant="contained"
                                className="upload-button"
                                disabled={isUploading}
                            >
                                {isUploading ? 'Subiendo...' : 'Subir Logo'}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            handleLogoUpload(file);
                                        }
                                    }}
                                />
                            </Button>

                            {previewLogo && (
                                <Box className="logo-preview-container" mt={2}>
                                    <img 
                                        src={previewLogo}
                                        alt="Logo Preview"
                                        className="logo-preview"
                                    />
                                    <IconButton 
                                        onClick={handleRemoveLogo}
                                        color="error"
                                        size="small"
                                        className="remove-logo-button"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveConfig}
                        disabled={isLoading || isUploading}
                        className="save-button"
                    >
                        {isLoading ? 'Guardando...' : 'Guardar Configuración'}
                    </Button>
                </>
            )}

            <Box className="configurations-table-section">
                <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
                    Configuraciones Existentes
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Módulos Activos</TableCell>
                                <TableCell>Color Principal</TableCell>
                                <TableCell>Logo</TableCell>
                                <TableCell align="center">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configuredClients.map((config) => (
                                <TableRow key={config.clientId}>
                                    <TableCell>{config.clientName}</TableCell>
                                    <TableCell>
                                        <Chip 
                                            label={`${getEnabledModulesCount(config.modules)} módulos`}
                                            color="primary"
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                width: 50,
                                                height: 25,
                                                backgroundColor: config.theme.primaryColor,
                                                border: '1px solid #ddd',
                                                borderRadius: 1
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {config.theme.logo ? (
                                            <Box sx={{ width: 50, height: 25 }}>
                                                <img 
                                                    src={config.theme.logo.url}
                                                    alt="Logo"
                                                    style={{ 
                                                        maxWidth: '100%', 
                                                        maxHeight: '100%', 
                                                        objectFit: 'contain' 
                                                    }}
                                                />
                                            </Box>
                                        ) : 'No'}
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                            <IconButton 
                                                onClick={() => handleEditConfig(config.clientId)}
                                                color="primary"
                                                size="small"
                                                title="Editar configuración"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => navigate(`/add-user-client/${config.clientId}`)}
                                            >
                                                Agregar Usuario
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default ClientPortalConfig;