import JSZip from 'jszip';
import jsPDF from 'jspdf';
import logo from '../.././images/logo337.png';
import { parse, format } from 'date-fns';
import "./DownloadModal.scss";

export function getFolderName(documentType) {
    switch (documentType) {
        case 'legal':
            return 'documentos-legales';
        case 'ims':
            return 'documentos-ims';
        case '112':
            return 'documentos-112';
        case 'denuncia':
            return 'documentos-denuncias';
        case 'intervencion':
            return 'documentos-intervenciones';
        case 'parte de trabajo':
            return 'documentos-partes-trabajo';
        default:
            return 'documentos';
    }
}

/**
* Descarga las fotos seleccionadas de los documentos.
* @param {Array} documents - Documentos seleccionados.
* @param {Object} folder - Carpeta donde se guardarán las fotos en el ZIP.
*/
export async function downloadSelectedPhotos(documents, folder) {
    for (const document of documents) {
        if (document.fields && document.fields.fotos) {
            const urls = Object.values(document.fields.fotos);
            const folderName = getFolderName(document.documentTypeId);
            for (let i = 0; i < urls.length; i++) {
                const formattedDate = formatDateForFolder(document.fecha); // Formatear la fecha
                const response = await fetch(urls[i]);
                const blob = await response.blob();
                const photoFileName = `${formattedDate}-${document.tienda}-${document.id}-foto-${i}.jpg`;
                if (!folder.folder(folderName).folderExists) {
                    folder.folder(folderName);
                }
                folder.folder(folderName).file(photoFileName, blob);
            }
        }
    }
}

/**
 * Formatea la fecha de "dd/mm/aaaa" a "dd-mm-aaaa".
 * @param {string} dateString - Fecha en formato "dd/mm/aaaa".
 * @returns {string} - Fecha formateada en "dd-mm-aaaa".
 */
export function formatDateForFolder(dateString) {
    const parts = dateString.split('/');
    if (parts.length === 3) {
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        return `${day}-${month}-${year}`;
    }
    return dateString; // Devuelve la fecha original si el formato no es válido
}

export async function downloadSelectedDocumentsAsPDF(documents, folder, exportType) {

    const logoPath = logo; // Ruta y nombre del archivo de imagen del logo
    const logoData = await fetch(logoPath);
    const logoBlob = await logoData.blob();
    const logoUrl = URL.createObjectURL(logoBlob);
    const logoWidth = 170; // Ancho del logo en píxeles
    const logoHeight = 70; // Alto del logo en píxeles


    for (const document of documents) {
        if (
            document.documentTypeId === 'parte de trabajo' ||
            document.documentTypeId === 'intervencion' ||
            document.documentTypeId === '112' ||
            exportType === 'pdf'
        ) {
            const pdf = new jsPDF('p', 'pt', 'letter', true); // Crear documento PDF con orientación vertical y tamaño de página 'letter'

            // Configuración de márgenes
            const margin = 20;
            const contentWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
            const contentHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

            // Logo
            const logoPositionX = pdf.internal.pageSize.getWidth() - margin - logoWidth; // Posición X en la esquina derecha
            const logoPositionY = margin; // Posición Y en la esquina superior
            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);

            // Agregar nombre del documento centrado en amarillo oscuro en la parte superior
            pdf.setFontSize(24);
            pdf.setTextColor('#FFBF00'); // Amarillo
            pdf.setFont('helvetica', 'bold');
            const documentName = document.documentTypeId.toUpperCase();
            const documentNameWidth = pdf.getStringUnitWidth(documentName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const documentNameX = pdf.internal.pageSize.getWidth() / 2 - documentNameWidth / 2;
            const documentNameY = margin + logoHeight + 50;
            pdf.text(documentName, documentNameX, documentNameY);

            // Estilos para el contenido de texto
            const smallerFontSize = 12;
            pdf.setFontSize(smallerFontSize);
            pdf.setTextColor('#000000'); // Negro
            pdf.setFont('helvetica', 'bold');

            // Dibujar los campos adicionales
            let textY = margin + 25;
            const vigilanteValue = document.vigilante.replace('@gmail.com', ''); // Remover la terminación @gmail.com
            pdf.text(`Vigilante: ${vigilanteValue}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Servicio: ${document.tienda || ''}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Servicio: ${document.cliente || ''}`, margin, textY + documentNameY);
            textY += 20;
            pdf.text(`Fecha de subida: ${document.fecha || ''}`, margin, textY + documentNameY);
            textY += 20;

            // Separación de dos líneas
            textY += 20;

            const observaciones = document.fields.observaciones || '';
            const turno = document.fields.turno || '';

            // Otros campos
            pdf.setFont('helvetica', 'normal');
            let otherFields = Object.entries(document.fields)
                .filter(([field, value]) => field !== 'observaciones' && field !== 'turno' && field !== 'FECHA' && field !== 'fotos')
                .map(([field, value]) => `${capitalizeFirstLetter(field)}: ${value}`)
                .join('\n\n');

            // Agregar turno si está presente
            if (turno) {
                otherFields += `\n\n${capitalizeFirstLetter('turno')}: ${turno}`;
            }

            // Agregar observaciones si está presente
            if (observaciones) {
                otherFields += `\n\n${capitalizeFirstLetter('observaciones')}: ${observaciones}`;
            }
            

            const otherText = otherFields;
            // let fotoY = textY + documentNameY + 40; // Espacio después del texto

            const options = { width: contentWidth, align: 'left' };
            const lines = pdf.splitTextToSize(otherText, contentWidth);
            // Mover la posición del texto después de las imágenes
            textY += 150;
            pdf.text(lines, margin, textY, options);


            // Agregar footer amarillo
            const footerHeight = 10;
            pdf.setFillColor('#FFBF00'); // Amarillo
            pdf.rect(margin, pdf.internal.pageSize.getHeight() - margin - footerHeight, contentWidth, footerHeight, 'F');

            // Configurar color de texto en blanco
            pdf.setTextColor('#FFFFFF'); // Blanco

            pdf.setFontSize(10);
            // pdf.text(footerText, margin + 10, pdf.internal.pageSize.getHeight() - margin - footerHeight + 15);

            // Después de agregar el contenido principal del PDF pero antes de guardar el PDF
            // Agregar una nueva página
            pdf.addPage();

            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);
            // Configurar color de texto en blanco para la nueva página
            pdf.setTextColor('#000000'); // Negro

            
            // Verificar si hay fotos y agregarlas al PDF si existen
            const fotos = document.fields.fotos;
            if (fotos && typeof fotos === 'object') {
                let fotoIndex = 0;
                const fotoHeight = 250; // Altura fija de la imagen
                const fotoWidth = 250; // Ancho fijo de la imagen
                let fotoX = 50;
                let fotoY =  100; // Espacio después del texto
                for (const key in fotos) {
                    if (Object.hasOwnProperty.call(fotos, key)) {
                        const fotoUrl = fotos[key];
                        const fotoData = await fetch(fotoUrl);
                        const fotoBlob = await fotoData.blob();
                        const fotoUrlForPdf = URL.createObjectURL(fotoBlob);
                        // Ajustar la posición de la imagen
                        if (fotoX + fotoWidth > contentWidth + margin) {
                            fotoX = margin;
                            fotoY += fotoHeight + 10; // Espacio entre las imágenes
                        }
                        pdf.addImage(fotoUrlForPdf, 'JPEG', fotoX, fotoY, fotoWidth, fotoHeight);
                        fotoX += fotoWidth + 10; // Espacio entre las imágenes
                    }
                }
            }

            // SEGUNDA PÁGINA: Firmas
            pdf.addPage();
            pdf.addImage(logoUrl, 'PNG', logoPositionX, logoPositionY, logoWidth, logoHeight);

            let currentY = logoPositionY + logoHeight + 50;

            // Título de la sección de firmas
            pdf.setFontSize(16);
            pdf.setTextColor('#000000');
            pdf.setFont('helvetica', 'bold');
            pdf.text('Firmas del documento', margin, currentY);
            currentY += 40;

            // Procesar firmas
            for (const [field, value] of Object.entries(document.fields)) {
                if (typeof value === 'string' && value.includes('signatures')) {
                    try {
                        const signatureResponse = await fetch(value);
                        const signatureBlob = await signatureResponse.blob();
                        const signatureUrl = URL.createObjectURL(signatureBlob);

                        // Título del campo de firma
                        pdf.setFontSize(14);
                        pdf.setTextColor('#000000');
                        pdf.setFont('helvetica', 'bold');
                        pdf.text(capitalizeFirstLetter(field), margin, currentY);
                        currentY += 25;

                        // Agregar la firma
                        const signatureWidth = 200;
                        const signatureHeight = 100;
                        pdf.addImage(signatureUrl, 'PNG', margin, currentY, signatureWidth, signatureHeight);
                        currentY += signatureHeight + 40;

                        URL.revokeObjectURL(signatureUrl);
                    } catch (error) {
                        console.error('Error al procesar la firma:', error);
                    }
                }
            }

            // Agregar texto al nueva página
            const footerText = `
                                                                      CLAUSULA LEGAL

    La información contenida en este documento es confidencial y está destinada a ser leída solo por la(s) persona(s)
    a la(s) que va dirigida. Si usted lee este documento y no es su destinatario o no está expresamente autorizado
    para leerlo, le informamos que pueda ser ilegal cualquier divulgación, distribución o reproducción del mismo,
    por lo que le instamos a que nos lo notifique inmediatamente y proceda a su destrucción.
    Recuerde en todo caso que, quienes intervengan en cualquier fase del tratamiento de datos de carácter personal
    están obligados al secreto profesional respecto de los mismos y al deber de guardarlos, obligaciones que subsistirán
    aún después de finalizar sus relaciones con el titular del fichero de datos o, en su caso, con el responsable del
    mismo (Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos 
    digitales).                  
`;
            pdf.setFontSize(10);
            pdf.text(footerText, margin + 10, margin + 600); // Ajusta las coordenadas según tu diseño

            pdf.setFillColor('#FFBF00'); // Amarillo
            pdf.rect(margin, pdf.internal.pageSize.getHeight() - margin - footerHeight, contentWidth, footerHeight, 'F');

            const pdfBytes = pdf.output('arraybuffer');

            const documentTypeFolder = folder.folder(getFolderName(document.documentTypeId));

            const moment = require('moment');
            const formattedDate = moment(document.fecha, 'D/M/YYYY').format('D-M-YYYY');

            documentTypeFolder.file(`${formattedDate}-${document.tienda}-${document.id}.pdf`, pdfBytes);
        }
    }
}



export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


/**
* Descarga los documentos seleccionados como archivos CSV dentro de una carpeta ZIP.
* @param {Array} documents - Documentos seleccionados.
* @param {Object} folder - Carpeta donde se guardarán los archivos en el ZIP.
*/
export function downloadSelectedCSV(documents, folder, exportType) {
    const csvDocs = documents.filter((doc) => doc.documentTypeId === "112");
    if (csvDocs.length > 0 || exportType === 'csv') {
        // Agrupar los documentos 112 en uno solo
        const header = Object.keys(csvDocs[0].fields);
        header.unshift("fecha");
        header.unshift("vigilantes");
        header.unshift("tiendas");

        const csvRows = [];
        for (const document of csvDocs) {
            // Agregar la fila correspondiente al CSV
            const values = Object.values(document.fields);
            const rowData = values.map((value) => {
                return typeof value === "string" ? value : JSON.stringify(value);
            });
            const formattedDate = formatDateForFolder(document.fecha); // Formatear la fecha
            rowData.unshift(formattedDate);
            rowData.unshift(document.vigilante);
            rowData.unshift(document.tienda);
            csvRows.push(rowData.join(","));
        }

        // Convertir el arreglo csvRows en una cadena separada por saltos de línea
        const csvContent = [header.join(","), ...csvRows].join("\n");

        const folderName = getFolderName(csvDocs[0].documentTypeId);
        folder = folder.folder(folderName);
        folder.file(`${folderName}.csv`, csvContent);
    } else {
        console.log("No hay documentos del tipo 112 seleccionados.");
    }
}

/**
 * Verifica si una fecha está dentro de los últimos N días.
 * Versión mejorada que maneja zonas horarias adecuadamente.
 *
 * @param {string|Date} date - La fecha a comprobar en formato 'dd/MM/yyyy' o un objeto Date
 * @param {number} n - Número de días atrás para comparar
 * @returns {boolean} - True si la fecha está dentro del periodo, false de lo contrario
 */
export function isWithinLastNDays(date, n) {
    try {
        // Convertir a objeto Date si es string
        let targetDate;
        if (typeof date === 'string') {
            const parts = date.split('/');
            if (parts.length === 3) {
                // Formato dd/MM/yyyy
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1; // Meses en JS son 0-11
                const year = parseInt(parts[2], 10);
                targetDate = new Date(year, month, day);
            } else {
                // Intentar parsear como fecha normal
                targetDate = new Date(date);
            }
        } else {
            targetDate = date instanceof Date ? date : new Date(date);
        }

        // Validar que la fecha sea válida
        if (isNaN(targetDate.getTime())) {
            console.error("Fecha inválida:", date);
            return false;
        }

        // Normalizar a inicio del día para evitar problemas con horas
        targetDate.setHours(0, 0, 0, 0);
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Calcular diferencia en días
        const differenceMs = today.getTime() - targetDate.getTime();
        const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
        
        return differenceDays <= n;
    } catch (error) {
        console.error("Error en isWithinLastNDays:", error);
        return false;
    }
}

/**
 * Formatea una fecha para mostrarla en formato dd/mm/yyyy.
 * Versión mejorada con mejor manejo de errores.
 *
 * @param {string|Date} dateInput - Fecha de entrada
 * @returns {string} - Fecha formateada o cadena vacía en caso de error
 */
export function formatDate(dateInput) {
    try {
      // Si no hay entrada, retornar cadena vacía
      if (!dateInput) return '';
      
      // Si ya es una cadena con formato dd/mm/yyyy, devolverla
      if (typeof dateInput === 'string' && /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateInput)) {
        return dateInput;
      }
      
      let date;
      
      // Manejar formato español de toLocaleString ("dd/mm/yyyy, hh:mm:ss")
      if (typeof dateInput === 'string' && dateInput.includes('/') && dateInput.includes(':')) {
        // Extraer solo la parte de la fecha (antes de la coma)
        const datePart = dateInput.split(',')[0];
        const parts = datePart.split('/');
        
        if (parts.length === 3) {
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Meses en JS son 0-11
          const year = parseInt(parts[2], 10);
          date = new Date(year, month, day);
        } else {
          date = new Date(dateInput);
        }
      } else {
        // Otros formatos, intentar parseo directo
        date = new Date(dateInput);
      }
      
      // Validar fecha
      if (isNaN(date.getTime())) {
        return '';
      }
      
      // Formatear a dd/mm/yyyy
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      
      return `${day}/${month}/${year}`;
    } catch (error) {
      console.error("Error en formatDate:", error);
      return '';
    }
  }

/**
 * Aplica un filtro de fecha para determinar si un documento debe mostrarse según la fecha seleccionada.
 * Versión mejorada que funciona correctamente con todos los filtros.
 *
 * @param {Object} row - El documento/fila a filtrar
 * @param {string} documentDate - La fecha del documento en formato dd/MM/yyyy
 * @param {string} dateFilter - El tipo de filtro ('all', 'today', 'last3Days', 'lastWeek')
 * @returns {boolean} - True si el documento pasa el filtro, false en caso contrario
 */
export function applyDateFilter(row, documentDate, dateFilter) {
    // Si el filtro es "all", siempre retornar true
    if (dateFilter === "all") return true;
    
    try {
      // Implementación integrada de normalizeFecha
      const normalizarFecha = (dateInput) => {
        try {
          if (!dateInput) return null;
          
          let fecha;
          if (typeof dateInput === 'string') {
            // Detectar formato dd/mm/yyyy o d/m/yyyy
            if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateInput)) {
              const parts = dateInput.split('/');
              const day = parseInt(parts[0], 10);
              const month = parseInt(parts[1], 10) - 1; // Meses en JS son 0-11
              const year = parseInt(parts[2], 10);
              fecha = new Date(year, month, day);
            } else {
              // Intentar parsear directamente
              fecha = new Date(dateInput);
            }
          } else {
            fecha = new Date(dateInput);
          }
          
          // Validar fecha
          if (isNaN(fecha.getTime())) {
            return null;
          }
          
          // Normalizar a inicio del día
          fecha.setHours(0, 0, 0, 0);
          return fecha;
        } catch (error) {
          console.error("Error en normalización de fecha:", error);
          return null;
        }
      };
      
      // Normalizar la fecha del documento usando la implementación local
      const docDate = normalizarFecha(documentDate);
      if (!docDate) return false; // Si no se puede normalizar la fecha, excluir el documento
      
      switch (dateFilter) {
        case "today": {
          // Verificar si es hoy
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return docDate.getTime() === today.getTime();
        }
        
        case "last3Days": {
          // Verificar si está dentro de los últimos 3 días
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          
          const differenceMs = today.getTime() - docDate.getTime();
          const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
          
          return differenceDays <= 2; // 0 = hoy, 1 = ayer, 2 = anteayer
        }
          
        case "lastWeek": {
          // Verificar si está dentro de la última semana
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          
          const differenceMs = today.getTime() - docDate.getTime();
          const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
          
          return differenceDays <= 6; // 0-6 = últimos 7 días
        }
          
        default:
          return true;
      }
    } catch (error) {
      console.error("Error en applyDateFilter:", error);
      return true; // En caso de error, incluir el documento
    }
  }
  

/**
 * Componente de modal para mostrar el estado de la descarga.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.isOpen - Indica si el modal está abierto.
 * @param {Function} props.onClose - Función para cerrar el modal.
 * @param {number} props.progress - Progreso de la descarga en porcentaje.
 * @param {string} props.feedback - Retroalimentación de la descarga.
 * @returns {JSX.Element} - Elemento JSX que representa el modal.
 */
export const DownloadModal = ({ isOpen, onClose, progress, feedback }) => (
    <div className={`download-modal ${isOpen ? "open" : ""}`}>
        <div className="modal-content">
            <h3>Estado de la descarga</h3>
            {progress !== 0 && (
                <>
                    <p>Descargando...</p>
                    <p>Progreso: {progress.toFixed(2)}%</p>
                </>
            )}
            {feedback && <p>{feedback}</p>}
            <button onClick={onClose}>Aceptar</button>
        </div>
    </div>
);

/**
 * Convierte una fecha de varios formatos posibles a un objeto Date normalizado.
 * Mejora la compatibilidad con diferentes formatos de entrada.
 *
 * @param {string|Date} dateInput - Fecha de entrada en formato string o Date
 * @returns {Date} - Objeto Date normalizado
 */
export function normalizeFecha(dateInput) {
    try {
        if (!dateInput) return null;
        
        let fecha;
        if (typeof dateInput === 'string') {
            // Detectar formato dd/mm/yyyy o d/m/yyyy
            if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateInput)) {
                const parts = dateInput.split('/');
                const day = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1; // Meses en JS son 0-11
                const year = parseInt(parts[2], 10);
                fecha = new Date(year, month, day);
            } else {
                // Intentar parsear directamente
                fecha = new Date(dateInput);
            }
        } else {
            fecha = new Date(dateInput);
        }
        
        // Validar fecha
        if (isNaN(fecha.getTime())) {
            console.error("Fecha inválida en normalizeFecha:", dateInput);
            return null;
        }
        
        // Normalizar a inicio del día
        fecha.setHours(0, 0, 0, 0);
        return fecha;
    } catch (error) {
        console.error("Error en normalizeFecha:", error);
        return null;
    }
}