import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import "./styles/AddUser.scss"
import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@mui/material';
import {
    collection,
    addDoc,
    updateDoc,
    doc,
    arrayUnion,
} from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import {
    fetchAdmins,
    fetchClientesYTiendas,
    fetchUserData,
    fetchClientesPorAdministrativo,
    fetchTiendasPorCampoCliente,
    fetchClientesPorInspector
} from './firestoreService';
import { validateEmail } from './utils/validate';
import { comunidades, provinciasPorComunidad } from "../../zoneData.js"
import ProvinciasSelect from './utils/ProvinciasSelect.js';

const AddUser = () => {
    const { currentService, currentUser, adminData } = useContext(AuthContext);
    const [clientes, setClientes] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [userData, setUserData] = useState({});
    const [admins, setAdmins] = useState([]);
    const [clienteSeleccionado, setClienteSeleccionado] = useState('');
    const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
    const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
    const [tiendasFiltradas, setTiendasFiltradas] = useState([]);
    const [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
    const [provinciasSeleccionadas, setProvinciasSeleccionadas] = useState([]); // Nuevo estado para las provincias
    const [clientesFiltrados, setClientesFiltrados] = useState([]);
    const [newUserData, setNewUserData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        password: '',
        tipoUsuario: [],
        clientes: [],
        tiendas: [],
        provincias: [],
        comunidades: [],
    });

    const navigate = useNavigate(); // Obtener la función de navegación

    useEffect(() => {
        if (currentUser && currentService) {
            setIsLoading(true);
            fetchAdmins(currentService)
                .then(setAdmins)
                .catch(console.error)
                .finally(() => setIsLoading(false));
            fetchClientesYTiendas(currentService)
                .then(([clientesData, tiendasData]) => {
                    setClientes(clientesData);
                    setTiendas(tiendasData);
                    setTiendasFiltradas(tiendasData);
                })
                .catch(console.error);
            fetchUserData(currentUser, currentService)
                .then(setUserData)
                .catch(console.error)
                .finally(() => setIsLoading(false));
        }
    }, [currentUser, currentService]);

    useEffect(() => {
        if (currentUser && currentService && adminData) {
            if (userData?.tipoUsuario?.includes('administrativo')) {
                // Obtener clientes basados en el ID del administrador
                fetchClientesPorAdministrativo(userData.id, currentService)
                    .then(clientesFiltrados => {
                        setClientes(clientesFiltrados);
                    })
                    .catch(console.error);
            } else if (userData?.tipoUsuario?.includes('inspector')) {
                fetchClientesPorInspector(userData.id, currentService)
                    .then(clientesFiltrados => {
                        console.log("inspector", clientesFiltrados)
                        setClientes(clientesFiltrados);
                    })
                    .catch(console.error);
            }
        }
    }, [currentUser, currentService, userData]);

    // Ejemplo de uso en el componente
    useEffect(() => {
        if (clienteSeleccionado && currentService) {
            fetchTiendasPorCampoCliente(clienteSeleccionado, currentService)
                .then(setTiendas) // Actualiza el estado con las tiendas filtradas
                .catch(console.error);
        }
    }, [clienteSeleccionado, currentService]);

    const handleAddUser = async () => {
        // Validaciones
        if (!validateEmail(newUserData.correo)) {
            alert('Por favor, introduce una dirección de correo electrónico válida.');
            return;
        }

        if (newUserData.password.length < 6) {
            alert('La contraseña debe tener al menos 6 caracteres.');
            return;
        }
        try {
            setIsLoadingUser(true);
            // Verifica el tipo de usuario antes de añadir nuevos usuarios
            if (userData.tipoUsuario.includes('administrador')) {
                // Puedes añadir lógica específica para administradores aquí
                console.log('Usuario administrador añadiendo nuevos usuarios');
                await addNewUser();
            } else if (userData.tipoUsuario.includes('administrativo')) {
                // Puedes añadir lógica específica para administrativos aquí
                console.log('Usuario administrativo añadiendo nuevos usuarios');
                // Verifica que no se añada un administrador
                if (!newUserData.tipoUsuario.includes('administrador')) {
                    await addNewUser();
                } else {
                    console.error('Los administrativos no pueden añadir administradores');
                    alert('Los administrativos no pueden añadir administradores')
                }
            } else if (userData.tipoUsuario.includes('inspector')) {
                // Puedes añadir lógica específica para inspectores aquí
                console.log('Usuario inspector añadiendo nuevos inspectores');
                // Verifica que solo se añadan inspectores
                if (newUserData.tipoUsuario.includes('inspector') && newUserData.tipoUsuario.length === 1) {
                    await addNewUser();
                } else {
                    console.error('Los inspectores solo pueden añadir inspectores y deben seleccionar uno');
                    alert('Los inspectores solo pueden añadir inspectores');
                }
            }
            navigate(-1);
            setIsLoadingUser(false);
        } catch (error) {
            setIsLoadingUser(false);
            alert("Error al añadir al usuario")
            console.error('Error al registrar el nuevo usuario:', error);
        }
    };

    const addNewUser = async () => {
        try {
            const lowercaseEmail = newUserData.correo.toLowerCase(); // Convertir a minúsculas
            // Registra el nuevo usuario en Firebase Authentication
            const { user } = await createUserWithEmailAndPassword(auth, lowercaseEmail, newUserData.password);

            // Extrae el nombre de usuario antes del arroba
            const username = lowercaseEmail.split('@')[0];

            // Registra el nuevo usuario en el campo 'users' del servicio actual
            await updateDoc(
                doc(db, 'servicios', currentService.id),
                {
                    users: arrayUnion(username),
                }
            );

            // Registra el nuevo usuario en el campo 'admins' del servicio actual
            await updateDoc(
                doc(db, 'servicios', currentService.id),
                {
                    admins: arrayUnion(newUserData.correo),
                }
            );

            // Registra el nuevo usuario en la subcolección 'admins' del servicio actual
            // Registra el nuevo usuario en la subcolección 'admins' del servicio actual
            const adminDocRef = await addDoc(
                collection(db, 'servicios', currentService.id, 'admins'),
                {
                    correo: newUserData.correo,
                    nombre: newUserData.nombre,
                    tipoUsuario: newUserData.tipoUsuario,
                    eliminado: false,
                    clientes: newUserData.clientes,
                    tiendas: newUserData.tiendas,
                    userId: user.uid,
                    comunidades: comunidadesSeleccionadas,
                    provincias: provinciasSeleccionadas,
                    apellido: newUserData.apellido
                }
            );

            await updateClientesYTiendas(adminDocRef.id, newUserData.tipoUsuario);

            console.log('Nuevo usuario añadido con ID:', user.uid);
            alert('El usuario se ha añadido correctamente');
            setNewUserData({
                nombre: '',
                apellido: '',
                correo: '',
                password: '',
                tipoUsuario: [],
                clientes: [],
                tiendas: []
            });
        } catch (error) {
            console.error('Error al añadir el nuevo usuario:', error);
            alert('Error al añadir el usuario: ' + error);
        }
    };

    const updateClientesYTiendas = async (userId, tipoUsuario) => {
        const esAdministrativo = tipoUsuario.includes('administrativo');
        const esInspector = tipoUsuario.includes('inspector');

        // Actualizar clientes
        for (const clienteId of newUserData.clientes) {
            const clienteRef = doc(db, 'servicios', currentService.id, 'clientes', clienteId);
            if (esAdministrativo) {
                await updateDoc(clienteRef, { administrativos: arrayUnion(userId) });
            }
            if (esInspector) {
                await updateDoc(clienteRef, { inspectores: arrayUnion(userId) });
            }
        }

        // Actualizar tiendas
        for (const tiendaId of newUserData.tiendas) {
            const tiendaRef = doc(db, 'servicios', currentService.id, 'tiendas', tiendaId);
            if (esAdministrativo) {
                await updateDoc(tiendaRef, { administrativos: arrayUnion(userId) });
            }
            if (esInspector) {
                await updateDoc(tiendaRef, { inspectores: arrayUnion(userId) });
            }
        }
    };

    const handleChangeClientes = (event) => {
        const { value } = event.target;

        if (value.includes('all')) {
            // Manejar la selección de todos los clientes
            setIsAllClientesSelected(!isAllClientesSelected);
            setNewUserData(prevState => ({
                ...prevState,
                clientes: isAllClientesSelected ? [] : clientes.map(c => c.id),
                tiendas: isAllClientesSelected ? [] : tiendas.map(t => t.id)
            }));
        } else {
            // Manejar la selección de clientes específicos
            setIsAllClientesSelected(value.length === clientes.length);

            // Filtrar y establecer las tiendas basadas en los clientes seleccionados
            const nuevasTiendasFiltradas = tiendas.filter(tienda =>
                value.some(clienteId => tienda?.cliente === clienteId)
            );

            setTiendasFiltradas(nuevasTiendasFiltradas);
            setNewUserData(prevState => ({
                ...prevState,
                clientes: value,
                tiendas: nuevasTiendasFiltradas.map(t => t.id)
            }));
        }
    };

    const handleChangeTiendas = (event) => {
        const { value } = event.target;
        if (value.includes('all')) {
            if (isAllTiendasSelected) {
                setNewUserData({ ...newUserData, tiendas: [] });
                setIsAllTiendasSelected(false);
            } else {
                setNewUserData({ ...newUserData, tiendas: tiendas.map(t => t.id) });
                setIsAllTiendasSelected(true);
            }
        } else {
            setNewUserData({ ...newUserData, tiendas: value });
            setIsAllTiendasSelected(value.length === tiendas.length);
        }
    };


    const handleSelectAllTiendas = () => {
        const allTiendasIds = tiendasFiltradas.map((tienda) => tienda.id); // Utilizar solo los servicios filtrados

        setNewUserData((prevUserData) => ({
            ...prevUserData,
            tiendas: allTiendasIds,
        }));
    };

    const handleChangeComunidades = (event) => {
        const { value } = event.target; // 'value' son las comunidades seleccionadas
        setComunidadesSeleccionadas(value);

        // Filtrar provincias basadas en las comunidades seleccionadas
        const provinciasDisponibles = value.reduce((acc, comunidad) => {
            const provinciasDeLaComunidad = provinciasPorComunidad[comunidad] || [];
            return [...acc, ...provinciasDeLaComunidad];
        }, []);

        // Filtrar las provincias seleccionadas para asegurarse de que solo se incluyan
        // aquellas que pertenecen a las comunidades seleccionadas
        const nuevasProvinciasSeleccionadas = provinciasSeleccionadas.filter(provincia =>
            provinciasDisponibles.includes(provincia)
        );

        const clientesFiltrados = clientes.filter(cliente => value.includes(cliente.comunidad));
        setClientesFiltrados(clientesFiltrados);
        setProvinciasSeleccionadas(nuevasProvinciasSeleccionadas); // Actualizar el estado con las provincias filtradas
        setNewUserData({
            ...newUserData,
            comunidades: value,
            provincias: nuevasProvinciasSeleccionadas // Asegúrate de actualizar aquí también
        });
    };

    const handleChangeProvincias = (selectedProvincias) => {
        setProvinciasSeleccionadas(selectedProvincias); // Actualiza el estado local de provincias seleccionadas
        setNewUserData({ ...newUserData, provincias: selectedProvincias }); // Actualiza el objeto newUserData para incluir las provincias seleccionadas
    };

    console.log("newUserData ", newUserData)
    return (
        <div className="add-user-container">
            <h1>Añadir Nuevo Usuario</h1>
            <div className="form-container">
                <div className="form-control">
                    <TextField
                        label="Nombre"
                        value={newUserData.nombre}
                        onChange={(e) => setNewUserData({ ...newUserData, nombre: e.target.value })}
                    />
                </div>
                <div className="form-control">
                    <TextField
                        label="Apellidos"
                        value={newUserData.apellido}
                        onChange={(e) => setNewUserData({ ...newUserData, apellido: e.target.value })}
                    />
                </div>
                <div className="form-control">
                    <TextField
                        label="Correo"
                        value={newUserData.correo}
                        onChange={(e) => setNewUserData({ ...newUserData, correo: e.target.value })}
                    />
                </div>
                <div className="form-control">
                    <TextField
                        label="Contraseña"
                        type="password"
                        value={newUserData.password}
                        onChange={(e) => setNewUserData({ ...newUserData, password: e.target.value })}
                    />
                </div>
                <FormControl fullWidth>
                    <InputLabel id="tipoUsuario-label">Tipo de Usuario</InputLabel>
                    <Select
                        labelId="tipoUsuario-label"
                        id="tipoUsuario"
                        multiple
                        value={newUserData.tipoUsuario}
                        onChange={(e) => setNewUserData({ ...newUserData, tipoUsuario: e.target.value })}
                        label="Tipo de Usuario"
                    >
                        <MenuItem value="administrador">Administrador</MenuItem>
                        <MenuItem value="administrativo">Administrativo</MenuItem>
                        <MenuItem value="inspector">Inspector</MenuItem>
                    </Select>
                </FormControl>
                <ProvinciasSelect
                    comunidadesSeleccionadas={comunidadesSeleccionadas}
                    provinciasSeleccionadas={provinciasSeleccionadas} // Asegúrate de que este estado se pase correctamente
                    onSelectProvincias={handleChangeProvincias} // Pasa la función que actualiza las provincias seleccionadas
                />
                <FormControl fullWidth>
                    <InputLabel id="comunidades-label">Comunidades</InputLabel>
                    <Select
                        labelId="comunidades-label"
                        id="comunidades"
                        multiple
                        value={comunidadesSeleccionadas}
                        onChange={(e) => {
                            handleChangeComunidades(e);
                            setNewUserData({ ...newUserData, comunidades: e.target.value });
                        }}
                        label="Comunidades"
                    >
                        {comunidades.map((comunidad) => (
                            <MenuItem key={comunidad} value={comunidad}>
                                {comunidad}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="clientes-label">Clientes</InputLabel>
                    <Select
                        labelId="clientes-label"
                        id="clientes"
                        multiple
                        value={newUserData.clientes}
                        onChange={handleChangeClientes}
                        label="Clientes"
                    >
                        <MenuItem value="all">
                            <Checkbox checked={isAllTiendasSelected} />
                            <ListItemText primary="Seleccionar todos los clientes" />
                        </MenuItem>
                        {clientesFiltrados.map((cliente) => (
                            <MenuItem key={cliente.id} value={cliente.id}>
                                {cliente.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="tiendas-label">Servicios</InputLabel>
                    {newUserData.clientes.length > 0 && ( // Condición para renderizar solo si hay clientes seleccionados
                        <Select
                            labelId="tiendas-label"
                            id="tiendas"
                            multiple
                            value={newUserData.tiendas}
                            onChange={handleChangeTiendas}
                            label="Servicios"
                        >
                            <MenuItem value="all" onClick={handleSelectAllTiendas}>
                                <Checkbox checked={isAllTiendasSelected} />
                                <ListItemText primary="Seleccionar todos los servicios" />
                            </MenuItem>
                            {tiendasFiltradas.map((tienda) => (
                                <MenuItem key={tienda.id} value={tienda.id}>
                                    {tienda.numTienda}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>
            </div>
            <Button onClick={handleAddUser} variant="contained" color="primary" style={{ margin: '20px' }}>Añadir Usuario</Button>
            {isLoadingUser && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                    <CircularProgress size={20} style={{ marginRight: '10px' }} />
                    <p>Añadiendo usuario...</p>
                </div>
            )}
        </div>
    );
};

export default AddUser;